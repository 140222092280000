import { Container } from "reactstrap";

function MembershipTicketSection({ membership }) {
    return (
        <div>
            <Container>
                <div>
                    준비중입니다.
                </div>
            </Container>
        </div>
    );
}
export default MembershipTicketSection;