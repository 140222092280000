import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Tooltip, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button, UncontrolledCarousel, InputGroup, InputGroupText } from "reactstrap";
import { API, BACKEND_HOST } from "../../api";
import Navbar from "../../Components/Common/navbar";
import { getAccessToken } from "../../api/auth";

const CancelOptionsDetail = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  // const [optionProducts, setOptionProducts] = useState([])
  const [reservationOptions, setReservationOptions] = useState([]);

  const [reservationData, setReservationData] = useState(null);
  const [originalOptions, setOriginalOptions] = useState(null);

  const totalAmount = reservationOptions
    .map((item) => {
      return parseInt(item.price);
    })
    .reduce((sum, currValue) => {
      return sum + currValue;
    }, 0);

  React.useEffect(() => {
    if (id) {
      const accessToken = getAccessToken();

      // 첫 번째 요청
      API.get(`/package/get-reservation/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          const data = response.data;
          console.log("data:", data);
          setReservationData(data);

          // 두 번째 요청
          return API.get(`/package/option-list/?reserv_id=${data.id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        })
        .then((response) => {
          const data = response.data;
          console.log("option-list", data);
          setOriginalOptions(data.reservation_options);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const handleCancelReservationOption = (checkValue, reservId, optionId, name, price) => {
    // console.log("handleReservationOption", checkValue, reservId, optionId)
    let _reservOptions = [...reservationOptions];
    const _optionIdx = _reservOptions.findIndex((value) => {
      return value.reservId === reservId && value.optionId === optionId;
    });
    if (_optionIdx !== -1) {
      if (!checkValue) {
        // remove
        _reservOptions.splice(_optionIdx, 1);
      }
    } else if (checkValue) {
      // add
      _reservOptions = [
        ...reservationOptions,
        {
          reservId: reservId,
          name: name,
          optionId: optionId,
          price: price,
        },
      ];
    }

    setReservationOptions(_reservOptions);
  };

  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const cancelReservOptions = () => {
    const items = reservationOptions.map((item) => {
      return { id: item.optionId };
    });
    console.log("cancelReservOptions items", items);

    const accessToken = getAccessToken();

    // POST 요청
    API.post(
      "/package/cancel-reservation-options/",
      {
        reserv_options: items,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        if (data.result === "ok") {
          if (!alert("취소 요청이 처리 되었습니다.")) navigate("/my-page");
        } else if (data.error) {
          alert("취소 요청이 처리되지 않았습니다. " + data.error);
        } else {
          alert("취소 요청이 처리되지 않았습니다. " + data);
        }
      })
      .catch((error) => console.log(error));
  };

  window.onscroll = function () {
    scrollFunction();
  };
  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };
  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
            <Row className="px-2">
              <Col lg={12}>
                <Card>
                  {reservationData ? (
                    <CardBody className="px-0">
                      <Row className="gx-lg-5">
                        <Col xl={8}>
                          <div className="mt-xl-2 mt-5">
                            <div className="d-flex flex-column">
                              <div className="flex-grow-1">
                                <h4 style={{ fontFamily: "Gmarket-M" }}>{reservationData["name"]} 개별 옵션 취소하기</h4>
                              </div>
                              <div className="mt-2">
                                <div className="mx-2">자리 {reservationData["spot"]}</div>
                                <div className="mx-2">예약시작일 {reservationData["reservationDate"]}</div>
                              </div>
                            </div>

                            {originalOptions && originalOptions.length ? (
                              <div className="mt-5">
                                <div className="mb-2">
                                  <h5>취소할 옵션을 선택해 주세요</h5>
                                </div>
                                <div className="d-flex flex-row">
                                  {originalOptions.map((optionItem, idx) => {
                                    return (
                                      <div key={idx} className="form-check my-2" dir="ltr">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`${reservationData.id}-${idx}-old`}
                                          onChange={(e) => {
                                            handleCancelReservationOption(e.currentTarget.checked, reservationData.id, optionItem.id, optionItem.name, optionItem.price);
                                          }}
                                        />
                                        <Label for={`${reservationData.id}-${idx}-old`} style={{ fontSize: "12px" }}>
                                          {optionItem.name} <br />+{optionItem.price}원
                                        </Label>
                                      </div>
                                    );
                                  })}
                                </div>
                                {/* {JSON.stringify(reservationOptions)} */}
                              </div>
                            ) : null}

                            {/* <div className="mt-5">
                                                    <div className="mb-2"><h5>추가 옵션</h5></div>
                                                    <div className="d-flex flex-row justify-content-between">
                                                        {optionProducts.map((optionItem, idx) => {
                                                            return <div key={idx} className="form-check my-2" dir="ltr">
                                                                <Input type="checkbox" className="form-check-input" id={`${reservationData.id}-${optionItem.id}`}
                                                                    onChange={(e) => { handleReservationOption(e.currentTarget.checked, reservationData.id, optionItem.id, optionItem.name, optionItem.price) }}
                                                                />
                                                                <Label for={`${reservationData.id}-${optionItem.id}`} style={{ fontSize: "12px" }}>{optionItem.name} <br />+{optionItem.price}원</Label>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> */}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  ) : (
                    <div>loading...</div>
                  )}
                </Card>
              </Col>
            </Row>

            <div style={{ position: "fixed", bottom: "0", left: "50%", right: "0", zIndex: "4", maxWidth: "640px", width: "100%", transform: "translateX(-50%)" }}>
              <Button
                color="danger"
                className="btn fw-bold fs-15"
                style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M", margin: "0 auto", width: "100%", padding: "12px 0" }}
                size="lg"
                // onClick={() => {
                //     // navigate(`/add-options-order`, {
                //     //     state: {
                //     //         reservationName: reservationData["name"],
                //     //         spotName: reservationData["spot"],
                //     //         reservDate: reservationData["reservationDate"],
                //     //         reservId: reservationData.id,
                //     //         options: reservationOptions,
                //     //     }
                //     // })
                // }}
                onClick={useConfirm(`정말로 취소하시겠습니까?`, cancelReservOptions, () => {})}
                disabled={!reservationOptions.length}
              >
                옵션 취소하기
              </Button>
            </div>
          </Container>
        </div>

        {/* <button onClick={() => toTop()}
                className="btn btn-danger btn-icon landing-back-top"
                style={{ bottom: "10%" }}
                id="back-to-top"
            >
                <i className="ri-arrow-up-line"></i>
            </button> */}
      </div>
    </React.Fragment>
  );
};

export default CancelOptionsDetail;
