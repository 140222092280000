import { Container } from "reactstrap";
import styled from "styled-components";

const Title = styled.h2`  // Use a semantic heading for accessibility
  font-size: 16px;
  font-family: "Pretendard-SemiBold";
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #ff5a5f;
  color: #fff;
  padding: 10px 15px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-family: "Pretendard-Medium";
`;

function MembershipInfoSection({ membership }) {
    return (
        <div>
            <Container>
                <div>
                    <Title>멤버십 혜택</Title>
                    <div style={{paddingLeft: "12px"}}>
                        {membership?.product?.bullated_list?.bullats?.map((section, index) => (
                            <div key={index}>
                                <div style={{ fontSize: 14, fontFamily: "Pretendard-Medium", color: "#1d1d1d", marginTop: 10 }}>{section.content}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <Title>자주 묻는 질문 FAQ</Title>
                    {/* <Subtitle></Subtitle> */}
                    <div style={{paddingLeft: "12px"}}>
                        {membership?.product?.qna_list?.qna?.map((section, index) => (
                            <div key={index}>
                                <div style={{ fontSize: 14, fontFamily: "Pretendard-Bold", color: "#000", fontWeight: "bold", marginTop: 20 }}>{section.title}</div>
                                <div style={{ fontSize: 14, fontFamily: "Pretendard-Medium", color: "#1d1d1d", marginTop: 10 }}>{section.content}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default MembershipInfoSection;