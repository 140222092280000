import { SUBDOMAIN } from "../../../assets/meta/meta";
import { API } from "../../../api";

const HECTO_API_URL = SUBDOMAIN === 'test' ? "https://tbnpg.settlebank.co.kr" : "https://npg.settlebank.co.kr"

async function getHectoPayParams({
    orderId, amount,
    phone, user_id,
    email = "", username = "",
    taxFreeAmt = 0, taxAmt = 0, vatAmt = 0,
}) {
    var curr_date = new Date();
    var year = curr_date.getFullYear().toString();
    var month = ("0" + (curr_date.getMonth() + 1)).slice(-2).toString();
    var day = ("0" + (curr_date.getDate())).slice(-2).toString();
    var hours = ("0" + curr_date.getHours()).slice(-2).toString();
    var mins = ("0" + curr_date.getMinutes()).slice(-2).toString();
    var secs = ("0" + curr_date.getSeconds()).slice(-2).toString();

    const trdDt = year + month + day;
    const trdTm = hours + mins + secs;

    const body = {
        trdDt,
        trdTm,
        subdomain: SUBDOMAIN,
        method: 'card',
        mchtTrdNo: orderId,
        plainTrdAmt: String(amount),
        plainMchtCustId: user_id,
        plainCphoneNo: phone,
        // plainMchtCustNm: username,
        // plainEmail: email,
        // plainTaxFreeAmt: String(taxFreeAmt),
        // plainTaxAmt: String(taxAmt),
        // plainVatAmt: String(vatAmt),
    }
    if (username) body['plainMchtCustNm'] = username
    if (email) body['plainEmail'] = email
    if (taxFreeAmt) body['plainTaxFreeAmt'] = String(taxFreeAmt)
    if (taxAmt) body['plainTaxAmt'] = String(taxAmt)
    if (vatAmt) body['plainVatAmt'] = String(vatAmt)

    console.log('getHectoPayParams body', body)

    const formData = new FormData();
    // set the data
    for (var key in body) {
        formData.append(key, body[key]);
    }

    const result = await API.post('/accounts/hecto-encryptparams/', formData, {
        headers: {
            'Content-Type': 'x-www-form-urlencoded'
        }
    })

    return {
        ...result.data.encParams,
        hashCipher: result.data.hashCipher,
        mchtId: result.data.mchtId,
        clientKey: result.data.clientKey,
        method: 'card',
        mchtTrdNo: orderId,
        trdDt,
        trdTm,
    }
}

function requestPaymentHecto({
    mchtId, method, mchtTrdNo, trdDt, trdTm, trdAmt,
    mchtCustId, mchtCustNm, cphoneNo, email,
    taxFreeAmt, taxAmt, vatAmt,
    taxTypeCd,
    mchtName, mchtEName,
    pmtPrdtNm, 
    notiUrl, nextUrl, cancUrl,
    hashCipher,
}) {
    if (!SETTLE_PG && !window.SETTLE_PG) {
        throw new Error('SETTLE_PG is not defined');
    } else {
        if (!window.SETTLE_PG) window.SETTLE_PG = SETTLE_PG
    }

    window.SETTLE_PG.pay({
        env: HECTO_API_URL,
        mchtParam: SUBDOMAIN, //상점 파라미터, 백엔드 리다이렉트 URL에 사용

        mchtId,
        method,
        mchtTrdNo,
        trdAmt,
        trdDt,
        trdTm,
        mchtName,
        mchtEName,

        mchtCustId,
        mchtCustNm,
        cphoneNo,
        email,

        pmtPrdtNm,

        taxTypeCd,
        taxFreeAmt,
        taxAmt,
        vatAmt,

        notiUrl,
        nextUrl,
        cancUrl,

        // appScheme: "amazingcamp",
        // custIp: "",
        pktHash: hashCipher,
        ui: {
            // type:"popup",   //popup, iframe, self, blank
            // width: "430",   //popup창의 너비
            // height: "660"   //popup창의 높이
            type: "self",
        },
    }, function (rsp) {
        //iframe인 경우 전달된 결제 완료 후 응답 파라미터 처리
        console.log(rsp);
    });
}


export {
    getHectoPayParams,
    requestPaymentHecto,
    HECTO_API_URL,
}