import React, { useState } from 'react';

const SelectCardBox = ({ items, selectedItems, onChange, isMulti=false }) => {

    const handleItemClick = (item) => {
        // if (!isMulti) {
        //     setSelectedItems([item]);
        //     onChange(item);
        //     return;
        // }

        // const isSelected = selectedItems.includes(item);
        // let updatedItems = [];

        // if (isSelected) {
        //     updatedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
        // } else {
        //     updatedItems = [...selectedItems, item];
        // }

        // setSelectedItems(updatedItems);
        // onChange(updatedItems);

        if (!isMulti) {
            console.log('item', item);
            onChange(item);
            return;
        } else {
            let updatedItems = [];
            if (selectedItems && selectedItems.find(
                (selectedItem) => selectedItem === item
            )) {
                updatedItems = selectedItems.filter((selectedItem) => selectedItem.code !== item.code);
            } else {
                updatedItems = [...selectedItems, item];
            }
            onChange(updatedItems);
        }
            
    };

    return (
        <div className='d-flex flex-wrap'>
            {/* <div>{selectedItems ? selectedItems.map(item => (<span key={item.id}>{item.code}</span>)) : null}</div> */}
            {items.map((item) => (
                <div
                    key={item.id}
                    onClick={() => handleItemClick(item)}
                    style={{
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '5px',
                        // borderColor: selectedItems && selectedItems.find((selectedItem) => selectedItem.code === item.code) ? '#f6475f' : '#ddd',
                        borderColor: 'transparent',
                        backgroundColor: selectedItems && selectedItems.find((selectedItem) => selectedItem.code === item.code) ? 'rgba(246, 71, 95, 0.9)' : '#fff',
                        color: selectedItems && selectedItems.find((selectedItem) => selectedItem.code === item.code) ? '#fff' : '#000',

                        padding: '10px',
                        margin: '5px',
                        cursor: 'pointer',
                    }}
                >
                    {item.label}
                </div>
            ))}
        </div>
    );
};

export default SelectCardBox;