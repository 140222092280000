import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, NavItem, NavLink } from "reactstrap";
import { API, BACKEND_HOST, SITE_URL } from "../../api";
import classnames from "classnames";
import GiftKakaoList from "../../pages/UserPage/purchaseList/GiftKakaoList";
import GiftPhoneNumList from "../../pages/UserPage/purchaseList/GiftPhoneNumList";
import { getAccessToken } from "../../api/auth";
import { shareKakao } from "../../common/social/kakao/api";

const ReservationGiftModal = ({ info, setInfo, visible, setVisible, needRefresh }) => {
  const togModal = () => setVisible(!visible);

  const [customActiveTab, setcustomActiveTab] = useState("2");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const inviteUserForReservation = async (reservId, phoneNum) => {
    const phone_num = phoneNum.replaceAll("-", "");

    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        `/package/invite-user-for-gift/${reservId}/`,
        {
          reservId: reservId,
          phoneNum: phone_num,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const giftReservationToKakaoFriend = async (reservId, kakaoId) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        `/package/send-reservation-gift-kakao/${reservId}/`,
        {
          reservId: reservId,
          kakaoId: kakaoId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const giftReservationByUrl = async (reservId) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        `/package/send-reservation-gift-url/`,
        {
          reservationId: reservId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  if (!Object.keys(info).length) return null;

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3">
          <div className="my-3 d-flex justify-content-center  "></div>
          <ul className="list-group">
            <li className="list-group-item">예약ID : {info.id}</li>
            <li className="list-group-item">예약상품 : {info.name}</li>
            
            {info.reservationEndDate && info.reservationDate === info.reservationEndDate ?
              <li className="list-group-item">예약일 : {info.reservationDate}</li> : 
              <li className="list-group-item">예약일 : {info.reservationDate} ~ {info.reservationEndDate}</li>}
            
            <li className="list-group-item">자리 : {info.spot}</li>
            <li className="list-group-item">결제금액 : {info.amount}</li>
            <li className="list-group-item">상태 : {info.state}</li>
          </ul>

          <div style={{ marginTop: "24px" }}></div>
          <div className="d-flex justify-content-center mx-1 mb-4">
            <Button
              style={{
                fontFamily: "Gmarket-M",
                backgroundColor: "#f9e000",
                borderWidth: 0,
                color: "#444",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 20px",
                gap: "10px",
              }}
              onClick={() => {
                giftReservationByUrl(info.id)
                  .then((data) => {
                    console.log("giftReservationByUrl", data);
                    shareKakao({
                      title: `${info.name}`,
                      description: `예약상품을 선물 받았습니다.`,
                      imageUrl: info.image ? info.image : "",
                      shareUrl: `${SITE_URL}/gift/reservation/${data.gift_id}/`,
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                    alert("선물하기에 실패했습니다.");
                  })
                  .finally(() => {
                    setVisible(false);
                    needRefresh();
                  });
              }}
            >
              <i className="ri-gift-fill"></i>
              카카오톡으로 선물보내기
            </Button>
          </div>

          <div style={{ marginTop: "24px" }}></div>

          <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10" style={{ alignItems: "end" }}>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="fs-20">
                  <i className="ri-kakao-talk-fill"></i>
                </span>{" "}
                카톡친구 선물
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                전화번호로 선물
              </NavLink>
            </NavItem>
          </Nav>

          {customActiveTab === "1" ? <GiftKakaoList sendItemKakao={giftReservationToKakaoFriend} {...{ info, setInfo, visible, setVisible, needRefresh }} /> : null}
          {customActiveTab === "2" ? <GiftPhoneNumList sendItem={inviteUserForReservation} {...{ info, setInfo, visible, setVisible, needRefresh }} /> : null}
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default ReservationGiftModal;
