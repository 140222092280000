import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Tooltip,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    UncontrolledCarousel,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    UncontrolledAccordion,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import { API } from "../../api";
import { useUserStore } from "../../store/store";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";
import { DetailImgModal, DetailInfoModal, DetailUseAdviceModal } from "../../Components/Common/ManagerModal";
import { Link } from "react-router-dom";
import AmCarousel from "../../Components/AmCarousel";

function PackageDetailSection({
    id, startDate
}) {
    const navigate = useNavigate();

    const [product, setProduct] = useState({});

    const [detailImageEditModal, setDetailImageEditModal] = useState(false);
    const [detailInfoModal, setDetailInfoModal] = useState(false);
    const [detailUseAdviceModal, setDetailUseAdviceModal] = useState(false);

    const [optionProducts, setOptionProducts] = useState([]);
    const [reservationOptions, setReservationOptions] = useState([]);

    const [packageSeats, setPackageSeats] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [seatCollapse, setSeatCollapse] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalText, setModalText] = useState("");

    const [user] = useUserStore((state) => [state.user]);

    const toggleModal = (message = "") => {
        if (message) {
            setModalText(message);
        }
        setModal(!modal);
    };

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const [optionOpen, setOptionOpen] = useState("1");
    const optionToggle = (id) => {
        if (optionOpen === id) {
            setOptionOpen();
        } else {
            setOptionOpen(id);
        }
    };


    useEffect(() => {
        if (!startDate || !id) return;
        const fetchData = async () => {
            try {
                const productResponse = await API.get(`/package/items/${id}/`);
                const productData = productResponse.data;

                const productDateRes = await API.get(`/package/items/?date=${startDate}&id=${id}`);
                const productDateData = productDateRes.data[0];

                console.log("fetchData", productData);
                console.log("fetchData", productDateData);
                setOptionProducts(productData.options);

                setPackageSeats(productDateData.spots);

                if (user && user.membership && productDateData.membership_price && productDateData.membership_price < productDateData.price) {
                    setProduct({
                        ...productData,
                        price: productDateData.membership_price,
                        membershipApplied: true,
                        reserved: productDateData.reserved,
                    });
                } else {
                    setProduct({
                        ...productData,
                        price: productDateData.price,
                        membershipApplied: false,
                        reserved: productDateData.reserved,
                    });
                }

            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [id, startDate, user]);


    const handleSeats = (spot) => {

        if (!selectedSeats?.length) {
            setSelectedSeats([{ id: spot.id, name: spot.name }]);
        } else if (!selectedSeats.find(item => item.id === spot.id)) {
            setSelectedSeats([...selectedSeats, { id: spot.id, name: spot.name }]);
        } else {
            setSelectedSeats(selectedSeats.filter((ele) => ele.id !== spot.id));

            // remove options
            const _reservOptions = [...reservationOptions];
            const matchedOptions = _reservOptions.filter((value) => value.spotId === spot.id);

            for (let i = 0; i < matchedOptions.length; i++) {
                const _optionIdx = _reservOptions.findIndex((value) => {
                    return value.spotId === spot.id && value.optionId === matchedOptions[i].optionId;
                });
                if (_optionIdx !== -1) {
                    _reservOptions.splice(_optionIdx, 1);
                }
            }

            setReservationOptions(_reservOptions);
        }
    }

    const handleReservationOption = (checkValue, spotId, optionId, name, price, type, quantity, taxfree = false) => {
        let _reservOptions = [...reservationOptions];
        const _optionIdx = _reservOptions.findIndex((value) => {
            return value.spotId === spotId && value.optionId === optionId;
        });
        if (_optionIdx !== -1) {
            if (!checkValue) {
                // remove
                _reservOptions.splice(_optionIdx, 1);
            }
        } else if (checkValue) {
            // add
            _reservOptions = [
                ...reservationOptions,
                {
                    spotId: spotId,
                    name: name,
                    optionId: optionId,
                    price: price,
                    type: type,
                    quantity: quantity,
                    taxfree: taxfree,
                },
            ];
        }

        setReservationOptions(_reservOptions);
    };

    const handleReservationOptionQuantity = reservationOptions.flatMap((item) => {
        const clonedItems = [];
        for (let i = 0; i < item.quantity; i++) {
            clonedItems.push({ ...item });
        }
        return clonedItems;
    });

    if (!startDate || !id) {
        return (
            <React.Fragment>
                <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
                    <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
                        <Navbar />
                        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
                            <div>Loading...</div>
                            <Link to="/package" className="btn btn-danger text-white mt-3">
                                뒤로가기
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <div>
            <div className="mt-xl-2 mt-1">
                <div className="d-flex">
                    <div className="flex-grow-1 mb-2">
                        <h3 style={{ fontFamily: "Gmarket-B" }}>{product.name}</h3>
                    </div>
                </div>

                <div className="product-img-slider">
                    {product && product.images?.length ?
                        <AmCarousel interval={4000}
                            heightRatio={0.6}
                            objectFit="cover"
                            items={product.images?.map((item, index) => {
                                // console.log("item", item);
                                return item;
                            })} />
                        : null}
                </div>

                <div className="d-flex flex-wrap justify-content-between mt-3">
                    <div className="d-flex align-items-center">
                        <h5 className="fs-14 me-2">날짜</h5>
                        <h5 className="fs-14" style={{ color: "#FF5A5F" }}>
                            {startDate}
                        </h5>
                    </div>
                    {packageSeats && <div className="d-flex align-items-center">
                        <h5 className="fs-14 me-2">잔여</h5>
                        <h5 className="fs-14" style={{ color: "#FF5A5F" }}>
                            {packageSeats?.length - product?.reserved} / {packageSeats?.length}
                        </h5>
                    </div>}
                </div>

                <div className="d-flex flex-wrap justify-content-between mt-1">
                    <div className="d-flex align-items-center">
                        <h5 className="fs-14 me-2">가격</h5>
                        <h5 className="fs-14" style={{ color: "#FF5A5F" }}>
                            {product?.price?.toLocaleString()} 원 {product?.membershipApplied ? <span style={{ color: "red" }}>(멤버십가)</span> : null}
                        </h5>
                    </div>
                </div>


                <div className="product-content mt-4">
                    <div className="d-flex flex-wrap justify-content-between mt-3">
                        <div className="d-flex align-items-center">
                            <h5 className="fs-14 me-2 text-dark fw-bold" style={{ marginBottom: "10px", fontFamily: "Gmarket-M" }}>상세정보</h5>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: product.descript }} />
                    <div style={{ height: "20px" }} />
                </div>


                <div className="d-flex flex-wrap justify-content-between mt-3">
                    <div className="d-flex align-items-center" onClick={() => {
                        setSeatCollapse(!seatCollapse);
                    }}>
                        <h5 className="fs-14 me-2 text-dark fw-bold" style={{ marginBottom: "10px", fontFamily: "Gmarket-M" }}>
                            자리 {seatCollapse && <span>보기</span>}
                        </h5>
                    </div>
                </div>

                {!seatCollapse && <div>
                    <div className="live-preview mb-2" style={{}}>
                        <div style={{ margin: "0" }}>
                            <img src={product["seat_img"]} style={{ resizeMode: "contain", width: "100%" }} className="img-fluid" alt="" />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-start gap-2" style={{ position: "relative" }}>
                        {packageSeats && packageSeats.map((spot) => {
                            return (
                                <Button
                                    key={spot.id}
                                    className="btn-icon"
                                    color={selectedSeats && selectedSeats.find(item => item.id === spot.id) ? "danger" : "light"}
                                    onClick={() => {
                                        handleSeats(spot);
                                    }}
                                    disabled={!spot["is_able"] || product.price === 0}
                                >
                                    {spot["is_able"] && product.price !== 0 ? (
                                        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${spot.name}`}</div>
                                    ) : (
                                        <span style={{ color: "red" }}>x</span>
                                    )}
                                </Button>
                            );
                        })}
                    </div>
                </div>}

                <div className="mt-4">
                    <div>
                        {selectedSeats.map((spotItem, idx) => {
                            return (
                                <div key={spotItem.id}>
                                    <div className="mx-2 mt-4 mb-4" style={{ marginBottom: "10px", fontFamily: "Gmarket-M" }}>
                                        자리 번호 <span style={{ color: "#F6475F" }}>{spotItem.name}</span> 번
                                    </div>

                                    {optionProducts && optionProducts.length ? (
                                        <UncontrolledAccordion open={optionOpen} toggle={optionToggle} defaultOpen="1">
                                            {product.option_required && optionProducts.find((value) => value.type === "goods") ? (
                                                <AccordionItem>
                                                    <AccordionHeader targetId="1" style={{ fontFamily: "Gmarket-M" }}>
                                                        필수 옵션*
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId="1">
                                                        <div className="">
                                                            {optionProducts
                                                                .filter((item) => item.type === "goods")
                                                                .map((optionItem, idx) => {
                                                                    if (optionItem.type === "deposit") return <div key={optionItem.id}></div>;
                                                                    return (
                                                                        <div
                                                                            key={optionItem.id}
                                                                            className="form-check my-2 mx-2"
                                                                            dir="ltr"
                                                                            style={{ borderBottom: "1px solid #ededed", paddingBottom: "10px", paddingTop: "10px" }}
                                                                        >
                                                                            <Input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id={`${spotItem.id}-${optionItem.id}`}
                                                                                checked={reservationOptions.find((value) => value.spotId === spotItem.id && value.optionId === optionItem.id)}
                                                                                // disabled={optionItem.type === "soldout"}
                                                                                onChange={(e) => {
                                                                                    handleReservationOption(e.currentTarget.checked, spotItem.id, optionItem.id, optionItem.name, optionItem.price, optionItem.type, 1, optionItem.tax_free);
                                                                                }}
                                                                            />
                                                                            <Label for={`${spotItem.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                                                                {optionItem.name} <br />+{optionItem.price}원
                                                                            </Label>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            ) : null}

                                            {product.option_required && optionProducts.find((value) => value.type !== "goods") ? (
                                                <AccordionItem>
                                                    <AccordionHeader targetId="2" style={{ fontFamily: "Gmarket-M" }}>
                                                        선택 옵션
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId="2">
                                                        <div className="">
                                                            {optionProducts
                                                                .filter((item) => item.type !== "goods")
                                                                .map((optionItem, idx) => {
                                                                    if (optionItem.type === "deposit") return <div key={optionItem.id}></div>;
                                                                    return (
                                                                        <div key={optionItem.id} className="form-check my-2 mx-2" dir="ltr">
                                                                            <Input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id={`${spotItem.id}-${optionItem.id}`}
                                                                                checked={reservationOptions.find((value) => value.spotId === spotItem.id && value.optionId === optionItem.id)}
                                                                                disabled={optionItem.type === "soldout"}
                                                                                onChange={(e) => {
                                                                                    handleReservationOption(e.currentTarget.checked, spotItem.id, optionItem.id, optionItem.name, optionItem.price, optionItem.type, 1, optionItem.tax_free);
                                                                                }}
                                                                            />
                                                                            <Label for={`${spotItem.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                                                                {optionItem.name} <br />+{optionItem.price}원 &nbsp;{optionItem.type === "soldout" ? <span className="text-danger fw-bold">품절</span> : ""}
                                                                            </Label>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            ) : null}

                                            {!product.option_required ? (
                                                <AccordionItem>
                                                    <AccordionHeader targetId="1" style={{ fontFamily: "Gmarket-M" }}>
                                                        선택 옵션
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId="1">
                                                        <div className="">
                                                            {optionProducts.map((optionItem, idx) => {
                                                                if (optionItem.type === "deposit") return <div key={optionItem.id}></div>;
                                                                return (
                                                                    <div
                                                                        key={optionItem.id}
                                                                        className="form-check my-2 mx-2"
                                                                        dir="ltr"
                                                                        style={{ borderBottom: "1px solid #ededed", paddingBottom: "10px", paddingTop: "10px" }}
                                                                    >
                                                                        <Input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id={`${spotItem.id}-${optionItem.id}`}
                                                                            checked={reservationOptions.find((value) => value.spotId === spotItem.id && value.optionId === optionItem.id)}
                                                                            disabled={optionItem.type === "soldout"}
                                                                            onChange={(e) => {
                                                                                handleReservationOption(e.currentTarget.checked, spotItem.id, optionItem.id, optionItem.name, optionItem.price, optionItem.type, 1, optionItem.tax_free);
                                                                            }}
                                                                        />
                                                                        <Label for={`${spotItem.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                                                            {optionItem.name} <br />+{optionItem.price}원 &nbsp;{optionItem.type === "soldout" ? <span className="text-danger fw-bold">품절</span> : ""}
                                                                        </Label>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            ) : null}
                                        </UncontrolledAccordion>
                                    ) : null}
                                </div>
                            );
                        })}
                        {/* <div>{JSON.stringify(reservationOptions)}</div> */}
                    </div>
                </div>

                {optionProducts.find((item) => item.type === "deposit") ? (
                    <div>
                        <h4>청소보증금이란?</h4>
                        <div style={{ height: "22px" }} />
                        <p style={{ color: "#FF5A5F" }}>청결한 시설 관리 및 비품 관리를 위해 패키지 상품 구매 시 청소보증금이 자동결제됩니다.</p>
                        <p>시설 이용 후 정리를 완료하고 체크아웃 버튼을 누르면 관리자가 정리 사항을 확인합니다.</p>
                        <p style={{ color: "#FF5A5F" }}>체크아웃 요청이 승인 되면 자동 환불됩니다.</p>
                    </div>
                ) : null}

                <div className="product-content mt-4">
                    <div style={{ height: "20px" }} />
                    <div className="d-flex flex-wrap justify-content-between mt-3">
                        <div className="d-flex align-items-center">
                            <h5 className="fs-14 me-2 text-dark fw-bold" style={{ marginBottom: "10px", fontFamily: "Gmarket-M" }}>이용안내</h5>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: product.using_info }} />
                </div>

                <div style={{ height: "100px" }} />
            </div>


            <Row
                className="position-fixed d-flex flex-row justify-content-center"
                style={{
                    maxWidth: "640px",
                    bottom: "0px",
                    width: "100%",
                    zIndex: 999,
                    backgroundColor: "#fff",
                    paddingBottom: "25px",
                    paddingTop: "25px",
                    alignItems: "center",
                    borderTop: "1px solid #ededed",
                }}
            >

                {reservationOptions.length > 0 ? (
                    <div style={{ maxHeight: "200px", overflowY: "scroll", width: "100%" }}>
                        {reservationOptions.map((options, index) => (
                            <div
                                key={"reservationOptions" + index}
                                style={{
                                    width: "95%",
                                    padding: "20px 20px",
                                    margin: "8px auto",
                                    borderRadius: "10px",
                                    borderBottom: "1px solid #ededed",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: "#f4f4f4",
                                }}
                            >
                                {selectedSeats.map((spotItem, idx) =>
                                    spotItem.id === options.spotId ? (
                                        <span key={idx} style={{ maxWidth: "50%" }}>
                                            자리 {spotItem.name} / {options.name}
                                        </span>
                                    ) : <React.Fragment key={idx}></React.Fragment>
                                )}

                                <QuantityButtonInput
                                    quantity={reservationOptions[index].quantity}
                                    detailPage={true}
                                    onChange={(value) => {
                                        const _reservationOptions = [...reservationOptions];
                                        if (_reservationOptions[index].type === "extra") {
                                            if (value > 2) {
                                                alert("최대 2개까지 선택 가능합니다.");
                                                return;
                                            }
                                        }
                                        _reservationOptions[index].quantity = value;
                                        setReservationOptions(_reservationOptions);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
                <Button
                    // color="danger"
                    className="btn fw-bold fs-16"
                    style={{
                        width: "95%",
                        backgroundColor: "#F6475F",
                        border: "none",
                        padding: "14px 0px",
                        borderRadius: "12px",
                        fontFamily: "Gmarket-M",
                    }}
                    size="lg"
                    onClick={() => {
                        if (!selectedSeats.length) {
                            toggleModal("자리를 선택해 주세요.");
                            return;
                        }

                        if (product.option_required) {
                            if (!reservationOptions.find((value) => value.type === "goods")) {
                                toggleModal("필수 옵션을 선택해 주세요.");
                                return;
                            }
                        }
                        navigate(`/order`, {
                            state: {
                                type: 0, // "package"
                                product: product,
                                selectDate: startDate,
                                spotId: selectedSeats,
                                options: handleReservationOptionQuantity,
                                quantity: 1, // 나중에 티켓 수량 선택 시 이용할 필드
                                membership_product_id: "",
                            },
                        });
                    }}
                >
                    구매하기
                </Button>
            </Row>


            <Modal isOpen={modal} toggle={() => toggleModal("")}>
                <ModalBody>
                    <span style={{ fontFamily: "Gmarket-M" }}>{modalText ? modalText : ""}</span>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => toggleModal("")} style={{ backgroundColor: "#dd4056", border: "none" }}>
                        확인
                    </Button>
                </ModalFooter>
            </Modal>

            <DetailImgModal setVisible={setDetailImageEditModal} visible={detailImageEditModal} itemId={product.id} type={"package"} navigate={navigate} />
            <DetailInfoModal setVisible={setDetailInfoModal} visible={detailInfoModal} itemId={product.id} type={"package"} navigate={navigate} />
            <DetailUseAdviceModal setVisible={setDetailUseAdviceModal} visible={detailUseAdviceModal} itemId={product.id} type={"package"} navigate={navigate} />
        </div>
    );
}

export default PackageDetailSection;
