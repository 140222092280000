import React, { useCallback, useEffect, useMemo } from "react";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";

// {
//     "altText": " ",
//     "caption": " ",
//     "key": 886,
//     "src": "https://media.amazingticket.site/carousel/%E1%84%8B%E1%85%A5%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5%E1%86%BC%E1%84%87%E1%85%A1%E1%84%87%E1%85%A6%E1%84%8F%E1%85%B2-%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%87%E1%85%A2%E1%84%82%E1%85%A5.jpg",
//     "display_level": 0,
//     "link_url": "https://amazingcamp.site/32",
//     "title": null,
//     "description": null,
//     "data": null
// },

export default function AmCarousel({
    interval = 5000,
    items = [],
    style = {},
    heightRatio = 1,
    objectFit = "contain",
    // imageStyle = {}
}) {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const [containerWidth, setContainerWidth] = React.useState(0);

    const containerRef = React.useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            console.log("containerRef.current.clientWidth", containerRef.current.clientWidth);
            setContainerWidth(containerRef.current.clientWidth);
        }
    }, [containerRef.current]);

    const imageStyle = useMemo(() => {
        const _width = containerWidth;
        return {
            width: `${_width}px`,
            height: `${_width * heightRatio}px`,
            // objectFit: "cover",
            objectFit: objectFit
        };
    }, [containerWidth, heightRatio, objectFit]);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const onExiting = () => {
        setAnimating(true);
    }

    const onExited = () => {
        setAnimating(false);
    }

    if (!items || items.length === 0) return null;


    return (<div ref={containerRef}>
        {items.length === 1 ? <div style={{ ...style, position: "relative" }}>
            {items[0].link_url ?
                <a href={items[0].link_url ? items[0].link_url : "#"} target={items[0].link_url ? "_blank" : "_self"} rel="noreferrer">
                    <img src={items[0].src} alt={items[0].altText} style={{ ...styles.carouselImage, ...imageStyle }} />
                </a>
                : <img src={items[0].src} alt={items[0].altText} style={{ ...styles.carouselImage, ...imageStyle }} />
            }
            {items[0].title ? <div style={{ position: "absolute", bottom: "0", left: "0", right: "0", backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white", padding: "16px 14px 50px 14px" }}>
                {items[0].link_url ? <a href={items[0].link_url} target="_blank" rel="noreferrer"><h5 style={{ textAlign: "center", color: "white" }}>{items[0].title}</h5></a> : <h5 style={{ textAlign: "center", color: "white" }}>{items[0].title}</h5>}
                {items[0].description ? <div style={{ textAlign: "center" }}>{items[0].description}</div> : null}
            </div> : null}
        </div> : <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={interval}
            style={{ ...style}}
            enableTouch={true}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {items.map((item) => {
                return (
                    <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                    >
                        {item.link_url ?
                            <a href={item.link_url ? item.link_url : "#"} target={item.link_url ? "_blank" : "_self"} rel="noreferrer">
                                <img src={item.src} alt={item.altText} style={{ ...styles.carouselImage, ...imageStyle }} />
                            </a>
                            : <img src={item.src} alt={item.altText} style={{ ...styles.carouselImage, ...imageStyle }} />
                        }

                        {item.title ?
                            <div style={{ position: "absolute", bottom: "0", left: "0", right: "0", backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white", padding: "16px 14px 50px 14px" }}>
                                {item.link_url ? <a href={item.link_url} target="_blank" rel="noreferrer"><h5 style={{ textAlign: "center", color: "white" }}>{item.title}</h5></a> : <h5 style={{ textAlign: "center", color: "white" }}>{item.title}</h5>}
                                {item.description ? <div style={{ textAlign: "center" }}>{item.description}</div> : null}
                            </div>
                            : null}
                    </CarouselItem>
                );
            })}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>}
    </div>);
}

const styles = {
    carouselImage: {
        width: "100%",
        height: "auto",
    }
};