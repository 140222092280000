import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Card, CardBody, CardHeader, Col, Collapse, Container, Row, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { QRCodeSVG } from "qrcode.react";

import TicketGiftUseModal from "../../../../Components/modal/TicketGiftUseModal";
import jwtDecode from "jwt-decode";
import { API, BACKEND_HOST } from "../../../../api";
import moment from "moment";
import { getAccessToken } from "../../../../api/auth";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const TicketGift = (props) => {
  const navigate = useNavigate();

  const [items, setitems] = React.useState([]);
  const [modalData, setModalData] = React.useState({});
  const [modal, setModal] = React.useState(false);

  function tog_backdrop() {
    setModal(!modal);
  }

  React.useEffect(() => {
    setdata();
  }, [props]);

  const setdata = async () => {
    try {
      const accessToken = getAccessToken();
      if (accessToken) {
        let decoded = {};
        try {
          decoded = jwtDecode(accessToken);
        } catch (e) {
          alert("got error decode", accessToken);
        }
        const userId = decoded["user_id"];

        const userResponse = await API.get(`/accounts/users/${userId}/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const userData = userResponse.data;
        let phone_num = "";
        if (!!userData["전화번호"]) {
          phone_num = userData["전화번호"];
        }

        const ticketResponse = await API.get(`/ticket/ticket-gift/?recipient=${phone_num}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const ticketData = ticketResponse.data;
        let _tickets = ticketData.results;
        _tickets = _tickets.sort((a, b) => {
          return a.ticket_state === "사용안함" && (a.gift_state === "선물수신" || a.gift_state === "선물발신") ? -1 : +1;
        });
        _tickets = _tickets.map((ticket) => ({
          ...ticket,
          remaining: moment(ticket.expiration_date).startOf("day").diff(moment().startOf("day"), "days"),
        }));

        setitems(_tickets);
      }
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    }
  };

  const handelReceiveGift = async (giftId) => {
    try {
      if (window.confirm("선물을 수령하시겠습니까?")) {
        const accessToken = getAccessToken();

        const response = await API.put(`/ticket/receive-ticket-gift/${giftId}/`, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = response.data;
        console.log(data);
        setdata();
      }
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 d-flex flex-wrap justify-content-center">
          {items.length !== 0 ? (
            items.map((item, idx) => {
              return (
                // {
                //   "id": 5,
                //   "name": "소인이용권(테스트)",
                //   "sender": "이기환",
                //   "phone_num": "01073032625",
                //   "recipient": "이기환",
                //   "recipient_phone_num": "01073032625",
                //   "ticket_id": "4168",
                //   "ticket_state": "사용안함",
                //   "gift_state": "선물발신",
                //   "send_date": "2023-03-01T17:22:19.843075"
                //   }
                <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={idx} className="mx-3 my-2 rounded-3">
                  {item.ticket_state === "사용안함" ? (
                    <Item
                      visible={true}
                      onClick={() => {
                        if (item.gift_state === "선물수신") {
                          setModalData(item);
                          tog_backdrop();
                        }
                      }}
                      className="bg-white"
                    >
                      <div className="d-flex justify-content-between px-2 border-bottom">
                        <div className="card-title pt-3">{item.name}</div>
                        {item.gift_state === "선물수신" ? null : <p className="text-muted pt-3">{item.gift_state}</p>}
                      </div>
                      <div className="d-flex py-3">
                        <div className="avatar-lg mx-2">
                          <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                            <img src={item.image} alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div className="mt-1 px-3" style={{ width: "80%" }}>
                          {/* <p className="card-text text-muted">GIFT ID : {item.id} </p> */}
                          <p className="card-text text-muted">보낸이 : {item.sender} </p>
                          <p className="card-text text-muted">상태 : {item.ticket_state} </p>
                          <div style={{ textAlign: "end" }}>
                            {item.gift_state === "선물발신" ? (
                              <Button
                                style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
                                onClick={(e) => {
                                  handelReceiveGift(item.id);
                                }}
                              >
                                선물받기
                              </Button>
                            ) : (
                              <Button
                                style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
                                onClick={(e) => {
                                  setModalData(item);
                                  tog_backdrop();
                                }}
                              >
                                사용하기
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Item>
                  ) : (
                    <Item visible={false} className="bg-white">
                      <div className="d-flex justify-content-between px-2 border-bottom">
                        <div className="card-title pt-3">{item.name}</div>
                        <p className="text-muted pt-3">사용불가</p>
                      </div>
                      <div className="d-flex py-3">
                        <div className="avatar-md mx-2">
                          <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                            <img src={item.image} alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="card-text text-muted">보낸이 : {item.sender} </p>
                          <p className="card-text text-muted">
                            {item.remaining > 0 ? `잔여일수 ${item.remaining}일` : "기간만료"}
                          </p>
                          <p className="card-text text-muted">상태 : {item.ticket_state} </p>
                        </div>
                      </div>
                    </Item>
                  )}
                </Col>
              );
            })
          ) : (
            <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
              선물함이 비었습니다.
            </div>
          )}
        </Col>
      </Row>

      <TicketGiftUseModal setVisible={setModal} visible={modal} info={modalData} setInfo={setModalData} navigate={navigate} needRefresh={setdata} />
    </React.Fragment>
  );
};
export default TicketGift;
