import React from "react";
import { Modal, Button } from "reactstrap";
import { API, BACKEND_HOST } from "../../api";
import { getAccessToken } from "../../api/auth";

const ReservationCheckoutModal = (props) => {
  const [checkModal, setCheckModal] = React.useState(false);
  const { info } = props;

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const togCheckModal = () => {
    setCheckModal(!checkModal);
  };


  const changeReserv = async () => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/package/change-reservation/",
        {
          id: info.id,
          reservation: "퇴장",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };



  const changeReservGift = async () => {
    const accessToken = getAccessToken();

    try {
      const response = await API.put(
        `/package/checkout-reservation-gift/${info.id}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3">
          <div className="my-3 d-flex justify-content-center  ">
            <h4 style={{ fontFamily: "Gmarket-M" }}>체크아웃 확인 {info.gift_state ? "(선물)" : null}</h4>
          </div>
          <ul className="list-group">
            {/* <li className="list-group-item">예약ID : {info.id}</li> */}
            <li className="list-group-item">예약상품 : {info.name}</li>
            
            {info.reservationEndDate && info.reservationDate === info.reservationEndDate ?
              <li className="list-group-item">예약일 : {info.reservationDate}</li> : 
              <li className="list-group-item">예약일 : {info.reservationDate} ~ {info.reservationEndDate}</li>}
            
            <li className="list-group-item">자리 : {info.spot}</li>
            {info.options && info.options.length ? (
              <li className="list-group-item">
                <span>옵션: </span>
                {info.options.map((item, idx, obj) => {
                  return (
                    <span key={idx}>
                      {item.name}
                      {idx !== obj.length - 1 ? ", " : ""}
                    </span>
                  );
                })}
              </li>
            ) : null}
            <li className="list-group-item">상태 : {info.state}</li>
          </ul>
          <div className="my-3 d-flex justify-content-center">
            <span>체크아웃 요청 후 승인완료가 되면 더 이상 자리를 이용할 수 없습니다.</span>
          </div>
          {info.options && info.options.find((item) => item.type === "deposit") ? (
            <div>
              <p style={{ textAnchor: "middle" }}>
                체크아웃을 원하시면 ‘체크아웃 요청’을 누른 후, 체크아웃이 승인될 때까지 자리에서 잠시 대기해 주세요.
                <br />
                <span style={{ color: "red" }}>관리자가 청결 상태 확인 후 체크아웃을 승인합니다. 이후 최초 지불 되었던 청소보증금은 환불됩니다.</span>
              </p>
            </div>
          ) : null}
        </div>
        <div className="d-flex justify-content-center mx-1 pb-3">
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            color="light ms-2 my-2"
            onClick={() => {
              props.setInfo({});
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
            onClick={() => {
              if (!info.gift_state) {
                changeReserv()
                  .then((obj) => {
                    alert("예약 상태가 변경되었습니다!");
                    props.needRefresh();
                  })
                  .catch((err) => {
                    alert("예약 상태 변경에 실패하였습니다!");
                    console.log(err);
                  });
              } else {
                changeReservGift()
                  .then((obj) => {
                    console.log(obj);
                    if (obj.error) {
                      alert("예약(선물) 상태 변경에 실패하였습니다!");
                      return;
                    } else {
                      alert("예약(선물) 상태가 변경되었습니다!");
                    }
                    props.needRefresh();
                  })
                  .catch((err) => {
                    alert("예약(선물) 상태 변경에 실패하였습니다!");
                    console.log(err);
                  });
              }
              props.setVisible(!props.visible);
            }}
            className="btn btn-success ms-2 my-2"
          >
            체크아웃
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default ReservationCheckoutModal;
