import React from "react";
import { Modal, Button, Alert } from "reactstrap";
import { API, BACKEND_HOST } from "../../api";
import { getAccessToken } from "../../api/auth";

const PurchaseTicketModal = (props) => {
  const togModal = () => {
    props.setVisible(!props.visible);
  };
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const goCancel = () => {
    const accessToken = getAccessToken();

    API.post(
      "/ticket/cancel-ticket/",
      { id: props.info.id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.result === "ok") {
          if (!alert("취소 요청이 처리 되었습니다.")) props.setInfo({});
          props.setVisible(false);
          props.navigate(0);
        } else if (response.data["error"]) {
          console.log(response.data);
          alert("취소 요청이 처리되지 않았습니다. " + response.data["error"]);
        } else {
          alert("취소 요청이 처리되지 않았습니다. " + response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("취소 요청이 처리되지 않았습니다. " + error.toString());
      });
  };

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3 pt-4">
          {/* <h3>티켓 디테일</h3> */}
          <ul className="list-group">
            <li className="list-group-item">예약상품 : {props.info.name + "(" + props.info.bundleCount + ")"}</li>
            <li className="list-group-item">만료일 : {props.info.expiration_date}</li>
            <li className="list-group-item">결제일 : {props.info.orderDate}</li>
            <li className="list-group-item">환불금액 : {Math.round(props.info.amount * props.info.bundleCount * 0.1) * 10}</li>
          </ul>
          {props.info.bundleCount > 1 && !props.info.isCancelable ? (
            <div style={{ marginTop: "10px", paddingLeft: "5px", textAlign: "center", fontFamily: "Gmarket-M" }}>티켓 번들중 하나라도 사용하면 모두 남은 번들도 모두 환불 불가 합니다.</div>
          ) : (
            <div style={{ marginTop: "10px", paddingLeft: "5px", textAlign: "center", fontFamily: "Gmarket-M" }}>번들 상품의 경우 환불시 번들 모두 환불 됩니다.</div>
          )}
        </div>
        <div className="d-flex justify-content-center mx-1 mb-4">
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            onClick={() => {
              props.setInfo({});
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            color="danger ms-2 my-2"
            onClick={useConfirm(`정말로 취소하시겠습니까?`, goCancel, () => {})}
          >
            환불
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default PurchaseTicketModal;
