import { Container } from "reactstrap";
import Calendar from "../../Package/calendar";

function MembershipPackageSection({ membership }) {
    return (
        <div>
            <Calendar package_api="/package/package-products/" membership_product_id={membership.product.id} skip_to_order={true} />
        </div>
    );
}
export default MembershipPackageSection;