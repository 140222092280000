import React, { useState, useEffect } from "react";
import { Container, Nav, NavItem, NavLink, Tooltip } from "reactstrap";
import { useQuery } from "react-query";
import Navbar from "../../../Components/Common/navbar";
import { API, BACKEND_HOST } from "../../../api";
import { getAccessToken } from "../../../api/auth";
import styled from "styled-components";
import { useUserStore } from "../../../store/store";
import classnames from "classnames";
import MembershipPackageSection from "./PackageSection";
import MembershipTicketSection from "./TicketSection";
import NavBottomBar from "../../../Components/Common/NavBottomBar";
import MembershipInfoSection from "./MembershipInfoSection";

const GiftImg = styled.img`
  width: 120px;
  height: 120px;
  /* Add styling for your image (e.g., border-radius) if needed */
`;

const MembershipContainer = styled.div`
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Arrange elements vertically */
  align-items: center;
  margin-top: 20px; /* Replace marginTop */
`;

const TextContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center; /* Align text vertically */
`;

const InfoContainer = styled.div`
  margin: 0px 0; /* Replace marginVertical */
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center; /* Center text within the container */
`;

const MembershipDetailScreen = () => {
    const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);
    const accessToken = getAccessToken();
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const {
        data: membershipStatus,
        isLoading: isMembershipLoading,
        error: membershipError,
        refetch: getMembershipStatus,
    } = useQuery("membership-status", () =>
        API.get(`/membership/memberships/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
    );

    return (
        <React.Fragment>
            <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
                <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
                    <Navbar />
                    <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                            <div style={{ fontSize: 19, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>맴버십 전용 구매하기 </div>
                        </div>

                        {isMembershipLoading ? <div>loading...</div> : null}
                        {membershipError ? <div>error</div> : null}
                        {!isMembershipLoading && membershipStatus && membershipStatus.data.length > 0
                            ? membershipStatus.data.map((item, index) => (
                                <div key={index}>

                                    <MembershipContainer>
                                        <div>
                                            <div style={{
                                                width: 64,
                                                height: 64,
                                                borderRadius: 60,
                                                backgroundColor: "#f8f8f8",
                                                // color: "#ff6b6b",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: 24,
                                            }}><i className="ri-gift-fill"></i></div>
                                        </div>

                                        <TextContainer>
                                            <span style={{ fontSize: 14, fontFamily: "Pretendard-SemiBold" }}>
                                                {user ? user?.username : ""}님은&nbsp;
                                            </span>
                                            <span style={{ fontSize: 14, fontFamily: "Pretendard-Bold", color: "#ff5a5f" }}>
                                                {item?.product?.name}&nbsp;
                                            </span>
                                            <span style={{ fontSize: 14, fontFamily: "Pretendard-SemiBold" }}>회원입니다</span>
                                        </TextContainer>
                                    </MembershipContainer>

                                    <InfoContainer>
                                        <span style={{ fontSize: 14, fontFamily: "Pretendard-Medium", color: "#1d1d1d" }}>
                                            적용기간: {item.start_date} ~ {item.end_date}
                                        </span>
                                    </InfoContainer>



                                    <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10" style={{ alignItems: "end" }}>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                빠른예약
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                티켓구매
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                                                className={classnames({
                                                    active: customActiveTab === "3",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                이용방법
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    {customActiveTab === "1" ? <MembershipPackageSection membership={item} /> : null}
                                    {customActiveTab === "2" ? <MembershipTicketSection membership={item} /> : null}
                                    {customActiveTab === "3" ? <MembershipInfoSection membership={item} /> : null}

                                </div>
                            ))
                            : null}

                        {membershipStatus && membershipStatus.data.length === 0 ? (
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                                <div>
                                    <span style={{ fontSize: 14, fontFamily: "Pretendard-Medium", color: "#1d1d1d" }}>
                                        현재 맴버십이 없습니다.
                                    </span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, fontFamily: "Pretendard-Medium", color: "#1d1d1d" }}>
                                        맴버십을 구매하시면 더 많은 혜택을 받으실 수 있습니다.
                                    </span>
                                </div>
                            </div>
                        ) : null}

                    </Container>
                    <NavBottomBar activeBar={1} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default MembershipDetailScreen;