import { useEffect } from "react";
import { useState } from "react";

export default function SearchBox({ setQuery, query, count }) {
    const [search, setSearch] = useState(query.search || "");
    useEffect(() => {
        setSearch(query.search || "")
    }, [query.search]);

    return (
        <div className="search-box d-flex align-items-center mt-3 mb-2">
            <div className={"search-box me-2 d-inline-block col-9"}>
                <input
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            setQuery((prev) => ({ ...prev, search: e.target.value, page: 1 }));
                        }
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control search /"
                    placeholder={`${count} Search...`}
                    value={search}
                />
                <i className="bx bx-search-alt search-icon"></i>
            </div>
            <div className="d-flex col-3">
                <button
                    onClick={() => {
                        setQuery((prev) => ({ ...prev, search, page: 1 }));
                    }}
                    className="btn btn-primary h-100 w-100"
                >
                    Search
                </button>
                <div style={{width: "8px"}}></div>
            </div>
        </div>
    );
}