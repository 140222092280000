


const getNextSaturday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (일요일) ~ 6 (토요일)
    let daysUntilSaturday = (6 - dayOfWeek + 7) % 7; // 다음 토요일까지 남은 일 수
    const nextSaturday = new Date(today);
    if (daysUntilSaturday === 0) daysUntilSaturday = 7;
    nextSaturday.setDate(today.getDate() + daysUntilSaturday);
    return nextSaturday;
};
const getNextFriday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (일요일) ~ 6 (토요일)
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7; // 다음 금요일까지 남은 일 수
    const nextFriday = new Date(today);
    nextFriday.setDate(today.getDate() + daysUntilFriday);
    return nextFriday;
};
const getNextSunday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (일요일) ~ 6 (토요일)
    const daysUntilSunday = (7 - dayOfWeek + 7) % 7; // 다음 일요일까지 남은 일 수
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilSunday);
    return nextSunday;
};

export { getNextFriday, getNextSaturday, getNextSunday };