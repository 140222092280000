import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header">
      <option value="1"></option>
      <option value="2"></option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <select className="ql-color">
      <option value="red"></option>
      <option value="green"></option>
      <option value="blue"></option>
      <option value="orange"></option>
      <option value="violet"></option>
      <option value="#d0d1d2"></option>
      <option selected></option>
    </select>
    <select className="ql-background"></select>
    <button className="ql-link"></button>
    <button className="ql-image"></button>
  </div>
);
const SimpleToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
  </div>
);

const BlankToolbar = () => <div id="toolbar" style={{ height: 0, borderWidth: 0 }}></div>;

function MyEditor({ value, readOnly, disableToolbar, width, onTextChange = () => {}, onChange = null, noImage = false }) {
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const formats = ["header", "font", "size", "bold", "italic", "underline", "list", "bullet", "align", "color", "background", "image"];

  const handleText = (value) => {
    if (onChange === null) return;
    onChange(value);
  };

  return (
    <div>
      {disableToolbar ? <BlankToolbar /> : noImage ? <SimpleToolbar /> : <CustomToolbar />}
      <ReactQuill
        style={{ width: width, borderWidth: 0 }}
        modules={modules}
        formats={formats}
        value={value}
        onChange={(value, delta, source, editor) => {
          handleText(value);
          const textvalue = editor.getText();
          onTextChange(textvalue);
        }}
        readOnly={readOnly}
      />
    </div>
  );
}

export default MyEditor;
