import React, { useEffect } from "react";

import _default from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/themes/material_orange.css";
import Flatpickr from "react-flatpickr";

import Navbar from "../../Components/Common/navbar";
import PackageDetailSection from "./packageDetailSection";
import { useParams, useSearchParams } from "react-router-dom";
import { getNextFriday, getNextSaturday } from "../../common/utils/dateUtil";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

function PackageItemPage() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [startDate, setStartDate] = React.useState(moment(getNextSaturday()).format("YYYY-MM-DD"));

  const handleDate = (dates) => {
    if (!(dates[0] instanceof Date)) return [""];

    const startDate = moment(dates[0]).format("YYYY-MM-DD");
    console.log("startDate", startDate);
    setStartDate(startDate);

    setSearchParams({ "date": startDate });

    return [startDate];
  }

  useEffect(() => {
    if (searchParams.get("date")) {
      setStartDate(searchParams.get("date"));
    }
  }, [searchParams["date"]]);

  if (!id) return null;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa", minHeight: "100vh" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar activeBar={6} />

          <div className="back-to-list mt-4 px-2">
            <Link to="/package" className="">
              <i className="ri-arrow-left-line"></i> 목록으로
            </Link>
          </div>

          <Container className="justify-content-center" style={{ minHeight: "100vh" }}>

            <div className="pt-4">
              <Flatpickr
                value={[startDate]}
                onChange={(e) => {
                  console.log("flatpickr onchange", e);
                  handleDate(e);
                }}
                className="form-control fs-17"
                options={{
                  locale: _default.ko,
                  mode: "single",
                  dateFormat: "Y-m-d",
                  minDate: "today",
                  defaultDate: [getNextSaturday()],
                }}
              />
            </div>

            <PackageDetailSection
              id={id} startDate={startDate}
            />

          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PackageItemPage;