
export default function InquiryBadgeList({
    query,
    setQuery,

    user,

    availableSites,

    isAdminMode,
    setIsAdminMode,

    adminTargetSubdomain,
    setAdminTargetSubdomain,

    hasPermission,
    isTest,

    pageReset,
}) {

    return (
        <div>
            <div className="d-flex flex-row mb-2 gap-2">
                {hasPermission ? (
                    <>
                        <div
                            className="me-1"
                            style={{
                                borderRadius: 100,
                                padding: "0.4rem 0.8rem",
                                cursor: "pointer",
                                userSelect: "none",
                                backgroundColor: query.no_reply ? "#f6475f" : "#e9ecef",
                                color: query.no_reply ? "#fff" : "#000",
                                fontFamily: "Gmarket-M",
                                fontSize: "12px",
                            }}
                            onClick={() => {
                                setQuery(prev => ({ ...prev, no_reply: !query.no_reply ? "True" : "", page: 1 }));
                            }}
                        >
                            답변필요
                        </div>
                        <div
                            className="me-1"
                            style={{
                                borderRadius: 100,
                                padding: "0.4rem 0.8rem",
                                userSelect: "none",
                                cursor: "pointer",
                                backgroundColor: query.need_care ? "#f6475f" : "#e9ecef",
                                color: query.need_care ? "#fff" : "#000",
                                fontFamily: "Gmarket-M",
                                fontSize: "12px",
                            }}
                            onClick={() => {
                                setQuery(prev => ({ ...prev, need_extra_info: !query.need_care ? "True" : "", need_care: !query.need_care ? "True" : "", page: 1 }));
                            }}
                        >
                            확인필요
                        </div>
                    </>
                ) : null}

                {isTest && hasPermission ? (
                    <div
                        className="me-1"
                        style={{
                            borderRadius: 100,
                            padding: "0.4rem 0.8rem",
                            userSelect: "none",
                            cursor: "pointer",
                            backgroundColor: isAdminMode === true ? "#f6475f" : "#e9ecef",
                            color: isAdminMode === true ? "#fff" : "#000",
                            fontFamily: "Gmarket-M",
                            fontSize: "12px",
                        }}
                        onClick={() => {
                            setIsAdminMode(!isAdminMode);
                            setAdminTargetSubdomain("");
                            pageReset();
                        }}
                    >
                        관리자보기
                    </div>
                ) : null}

                <div
                    className="me-1"
                    style={{
                        borderRadius: 100,
                        padding: "0.4rem 0.8rem",
                        userSelect: "none",
                        cursor: "pointer",
                        backgroundColor: query.my_article ? "#f6475f" : "#e9ecef",
                        color: query.my_article ? "#fff" : "#000",
                        fontFamily: "Gmarket-M",
                        fontSize: "12px",
                    }}
                    onClick={() => {
                        setQuery(prev => ({ ...prev, phone_num: !query.my_article ? user?.phone : "", my_article: !query.my_article ? "True" : "", page: 1 }));
                    }}
                >
                    내가쓴글
                </div>
            </div>

            <div className="px-2">
                {isTest && hasPermission ? (
                    <>
                        <div className="d-flex flex-row mb-2 gap-2 mt-3">
                            <h4 style={{ fontSize: 16, margin: 0, padding: 0, fontFamily: "Gmarket-M", color: "#f6475f", lineHeight: "32px", width: "125px" }}>캠프</h4>
                            <div className="d-flex flex-row mb-2 gap-2 mt-1" style={{ flexWrap: "wrap" }}>
                                {availableSites
                                    .filter((siteType, i) => siteType.type.id === 1)
                                    .map((site, index) => (
                                        <div
                                            key={index}
                                            className="me-1"
                                            style={{
                                                borderRadius: 100,
                                                padding: "0.4rem 0.8rem",
                                                userSelect: "none",
                                                cursor: "pointer",
                                                backgroundColor: adminTargetSubdomain === site.subdomain ? "#f6475f" : "#e9ecef",
                                                color: adminTargetSubdomain === site.subdomain ? "#fff" : "#000",
                                                fontFamily: "Gmarket-M",
                                                fontSize: "12px",
                                            }}
                                            onClick={() => {
                                                setAdminTargetSubdomain(adminTargetSubdomain === site.subdomain ? "" : site.subdomain);
                                                setIsAdminMode(true);
                                                pageReset();
                                            }}
                                        >
                                            {site.short_name}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-2 gap-2 mt-3">
                            <h4 style={{ fontSize: 16, margin: 0, padding: 0, fontFamily: "Gmarket-M", color: "#f6475f", lineHeight: "32px", width: "60px" }}>파크</h4>
                            <div className="d-flex flex-row mb-2 gap-2 mt-1" style={{ flexWrap: "wrap" }}>
                                {availableSites
                                    .filter((siteType, i) => siteType.type.id === 2)
                                    .map((site, index) => (
                                        <div
                                            key={index}
                                            className="me-1"
                                            style={{
                                                borderRadius: 100,
                                                padding: "0.4rem 0.8rem",
                                                userSelect: "none",
                                                cursor: "pointer",
                                                backgroundColor: adminTargetSubdomain === site.subdomain ? "#f6475f" : "#e9ecef",
                                                color: adminTargetSubdomain === site.subdomain ? "#fff" : "#000",
                                                fontFamily: "Gmarket-M",
                                                fontSize: "12px",
                                            }}
                                            onClick={() => {
                                                setAdminTargetSubdomain(adminTargetSubdomain === site.subdomain ? "" : site.subdomain);
                                                setIsAdminMode(true);
                                                pageReset();
                                            }}
                                        >
                                            {site.short_name}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-2 gap-2 mt-3">
                            <h4 style={{ fontSize: 16, margin: 0, padding: 0, fontFamily: "Gmarket-M", color: "#f6475f", lineHeight: "32px", width: "60px" }}>바베큐</h4>
                            <div className="d-flex flex-row mb-2 gap-2 mt-1" style={{ flexWrap: "wrap" }}>
                                {availableSites
                                    .filter((siteType, i) => siteType.type.id === 5)
                                    .map((site, index) => (
                                        <div
                                            key={index}
                                            className="me-1"
                                            style={{
                                                borderRadius: 100,
                                                padding: "0.4rem 0.8rem",
                                                userSelect: "none",
                                                cursor: "pointer",
                                                backgroundColor: adminTargetSubdomain === site.subdomain ? "#f6475f" : "#e9ecef",
                                                color: adminTargetSubdomain === site.subdomain ? "#fff" : "#000",
                                                fontFamily: "Gmarket-M",
                                                fontSize: "12px",
                                            }}
                                            onClick={() => {
                                                setAdminTargetSubdomain(adminTargetSubdomain === site.subdomain ? "" : site.subdomain);
                                                setIsAdminMode(true);
                                                pageReset();
                                            }}
                                        >
                                            {site.short_name}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    )
}