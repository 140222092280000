import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Common/navbar";
import { useUserStore } from "../../store/store";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledCarousel,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  UncontrolledAccordion,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import { API } from "../../api";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";
import { DetailImgModal, DetailInfoModal, DetailUseAdviceModal } from "../../Components/Common/ManagerModal";

const TicketItemPage = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    const [permission] = useUserStore((state) => [state.permission]);

    const [product, setProduct] = useState({});

    const [quantity, setQuantity] = useState(1);

    const [detailImageEditModal, setDetailImageEditModal] = useState(false);
    const [detailInfoModal, setDetailInfoModal] = useState(false);
    const [detailUseAdviceModal, setDetailUseAdviceModal] = useState(false);

    const [modal, setModal] = useState(false);
    const [user] = useUserStore((state) => [state.user]);

    const toggleModal = () => setModal(!modal);

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                    const productResponse = await API.get(`/ticket/products/${id}/`);
                    const productData = productResponse.data;

                    if (user && user.membership && productData.membership_price && productData.membership_price < productData.price) {
                        setProduct({
                            ...productData, 
                            price: productData.membership_price,
                            membershipApplied: true
                        });
                    } else {
                        setProduct({
                            ...productData,
                            membershipApplied: false
                        });
                    }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [id, user]);


    return (
        <React.Fragment>
            <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
                <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
                    <Navbar />
                    <div>
                        <Container fluid style={{ minHeight: "100vh", paddingBottom: "300px" }}>
                            <Row>
                                <Col lg={12}>
                                    {!!permission && permission.permission > 0 ? (
                                        <Button className="btn btn-soft-warning mb-3 border-0 position-absolute top-25 end-0" style={{ zIndex: "2" }} onClick={() => setDetailImageEditModal(!detailImageEditModal)}>
                                            상세이미지 수정
                                        </Button>
                                    ) : null}
                                    <Card>
                                        <CardBody className="px-0">
                                            <Row className="gx-lg-5">
                                                <Col xl={12} md={12} className="mx-auto">
                                                    <div className="product-img-slider sticky-side-div">{product ? <UncontrolledCarousel interval={4000} items={product.images ? product.images : []} /> : null}</div>
                                                </Col>

                                                <Col xl={12}>
                                                    <div className="mt-xl-2 mt-5">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 mt-3">
                                                                <h3 style={{ fontFamily: "Gmarket-B" }}>{product.name}</h3>
                                                            </div>
                                                        </div>


                                                        <div className="product-content mt-5">
                                                            {/* <h5 className="fs-14 mb-3">Information :</h5> */}
                                                            <Nav tabs className="nav-tabs-custom nav-primary">
                                                                <NavItem>
                                                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1" })} onClick={() => toggleCustom("1")}>
                                                                        상세정보
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2" })} onClick={() => toggleCustom("2")}>
                                                                        이용안내
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                            {/* 이거 없애면됨 */}
                                                            <TabContent activeTab={customActiveTab} className="pt-4" id="nav-tabContent" style={{ padding: "0px 10px" }}>
                                                                <TabPane id="nav-detail" tabId="1">
                                                                    {!!permission && permission.permission > 0 ? (
                                                                        <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0" style={{ zIndex: "2" }} onClick={() => setDetailInfoModal(!detailInfoModal)}>
                                                                            상세정보 수정
                                                                        </Button>
                                                                    ) : null}
                                                                    <div dangerouslySetInnerHTML={{ __html: product.descript }} />
                                                                </TabPane>
                                                                <TabPane id="nav-speci" tabId="2">
                                                                    {!!permission && permission.permission > 0 ? (
                                                                        <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0" style={{ zIndex: "2" }} onClick={() => setDetailUseAdviceModal(!detailUseAdviceModal)}>
                                                                            이용안내 수정
                                                                        </Button>
                                                                    ) : null}
                                                                    <div dangerouslySetInnerHTML={{ __html: product["using_info"] }} />
                                                                </TabPane>
                                                            </TabContent>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row
                                className="position-fixed d-flex flex-row justify-content-center"
                                style={{
                                    maxWidth: "640px",
                                    bottom: "0px",
                                    width: "100%",
                                    zIndex: 999,
                                    backgroundColor: "#fff",
                                    paddingBottom: "25px",
                                    paddingTop: "25px",
                                    alignItems: "center",
                                    borderTop: "1px solid #ededed",
                                }}
                            >
                                    <div style={{ width: "25%", display: "inline-block", paddingInline: "8px", marginRight: "25px" }}>
                                        <QuantityButtonInput
                                            size={28}
                                            quantity={quantity}
                                            onChange={(value) => {
                                                if (product?.remaining_stock && value > product.remaining_stock) {
                                                    alert("구매 가능 개수를 초과하였습니다.");
                                                    return;
                                                }
                                                setQuantity(value);
                                            }}
                                        />
                                    </div>

                                <Button
                                    // color="danger"
                                    className="btn fw-bold fs-16"
                                    style={{
                                        width:  "60%" ,
                                        backgroundColor: "#F6475F",
                                        border: "none",
                                        padding: "14px 0px",
                                        borderRadius: "12px",
                                        fontFamily: "Gmarket-M",
                                    }}
                                    size="lg"
                                    onClick={() => {
                                            // 티켓 상품
                                            navigate(`/order-ticket-multi`, {
                                                state: {
                                                    products: [
                                                        {
                                                            ...product,
                                                            quantity: quantity,
                                                        },
                                                    ],
                                                },
                                            });
                                    }}
                                >
                                    구매하기
                                </Button>
                            </Row>
                        </Container>
                        <Modal isOpen={modal} toggle={toggleModal}>
                            <ModalBody>
                                <span style={{ fontFamily: "Gmarket-M" }}>필수 옵션을 선택해 주세요.</span>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={toggleModal} style={{ backgroundColor: "#dd4056", border: "none" }}>
                                    확인
                                </Button>
                            </ModalFooter>
                        </Modal>

                        <DetailImgModal setVisible={setDetailImageEditModal} visible={detailImageEditModal} itemId={product.id} type={"ticket"} navigate={navigate} />
                        <DetailInfoModal setVisible={setDetailInfoModal} visible={detailInfoModal} itemId={product.id} type={"ticket"} navigate={navigate} />
                        <DetailUseAdviceModal setVisible={setDetailUseAdviceModal} visible={detailUseAdviceModal} itemId={product.id} type={"ticket"} navigate={navigate} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TicketItemPage;
