import { Button } from "reactstrap";

export default function InquiryPagination({
    next,
    prev,
    count,
    page,
    setPage,

}) {

    if (!next && !prev) {
        return null;
    }

    return (
        <div className="mt-6 mb-6">
            <div className="pb-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                <div className="d-flex gap-1">
                    <Button
                        // color="primary"
                        onClick={() => {
                            if (!prev) return;
                            setPage(Number(page) - 1);
                        }}
                        disabled={!prev}
                        style={{
                            color: prev ? "#F6475F" : "#D3D3D3",
                            backgroundColor: "transparent", border: "none", fontSize: "18px", fontFamily: "Gmarket-M", boxShadow: "none"
                        }}
                    >
                        {"<"}
                    </Button>
                </div>

                <span style={{ fontFamily: "Gmarket-M", width: "90px", textAlign: "center" }}>
                    {Number(page)} / {Math.ceil(count / 15)}
                </span>

                <div className="d-flex gap-1">
                    <Button
                        // color="primary"
                        onClick={() => {
                            if (!next) return;
                            setPage(Number(page) + 1);
                        }}
                        disabled={!next}
                        style={{
                            color: next ? "#F6475F" : "#D3D3D3",
                            backgroundColor: "transparent", border: "none", fontSize: "18px", fontFamily: "Gmarket-M", boxShadow: "none"
                        }}
                    >
                        {">"}
                    </Button>
                </div>

            </div>
        </div>
    )
}