//Import Breadcrumb
import BreadCrumb from "./BreadCrumb";
import KakaoMap from "./KaKaoMap";
import Navbar from "../../Components/Common/navbar";
import { useUserStore } from "../../store/store";

const LoadingContainer = () => <div>Loading...</div>;

const CampMap = (props) => {
  const [permission] = useUserStore((state) => [state.permission]);

  document.title = "캠프MAP";

  return (
    <React.Fragment>
      <Navbar activeBar={4} />
      <KakaoMap permission={permission} />
    </React.Fragment>
  );
};

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
//   LoadingContainer: LoadingContainer,
//   v: "3",
// })(CampMap);

export default CampMap;