import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { API, BACKEND_HOST } from "../../../api";
import Loader from "../../../Components/Common/Loader";
import CommentBox from "./CommentBox";
import CommentInput from "./CommentInput";
import { useUserStore } from "../../../store/store";
import { getAccessToken } from "../../../api/auth";

function CommentList({ articleId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);

  const [focusedComment, setfocusedComment] = useState(null);

  const [permission, user] = useUserStore((state) => [state.permission, state.user]);

  const fetchData = async (articleId) => {
    setLoading(true);
    setError(null);
    const accessToken = getAccessToken();

    try {
      const response = await API.get(`/inquiry/comments/?article_id=${articleId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      console.log(data);

      if (data.error) {
        setError(data.error);
      } else {
        const _comments = data.map((item) => {
          let _username = item.username ? item.username[0] + "*".repeat(item.username.length - 1) : "";
          let profile_img = item.profile_img;

          if (!!permission && permission.permission) _username = item.username;
          if (!!user && item.user_id === user.id) _username = item.username;
          if (!!item.user_permission?.permission && !!permission && permission.permission) {
            _username = `관리자(${_username})`;
            profile_img = "/amazingpark-logo.png";
          }
          if (!!item.user_permission?.permission && (!permission || !permission.permission)) {
            _username = `관리자`;
            profile_img = "/amazingpark-logo.png";
          }

          return {
            ...item,
            username: _username,
            profile_img: profile_img,
          };
        });

        setComments(_comments);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(articleId);
  }, [articleId]);

  const handleDelete = async (commentId) => {
    try {
      const accessToken = getAccessToken();
      await API.delete(`/inquiry/comments/${commentId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("삭제 성공");
      fetchData(articleId);
    } catch (error) {
      console.error(error);
    }
  };
  if (loading)
    return (
      <Container>
        <Row className="mt-4 justify-content-center">
          <Loader loading={loading} />
        </Row>
      </Container>
    );
  if (!comments || error)
    return (
      <Container>
        <Row className="mt-4 justify-content-center">
          <span>댓글이 없습니다.</span>
        </Row>
      </Container>
    );
  return (
    // <div>{articleId} {JSON.stringify(comments)}</div>
    <div>
      <div>
        <span style={{ color: "#6d6d6d", fontFamily: "Gmarket-M" }}>댓글</span>
      </div>
      <div className="mb-4">
        {comments
          .filter((item) => !item.parent)
          .map((item) => (
            <CommentBox
              key={item.id}
              {...item}
              onReply={(commentObj) => setfocusedComment(commentObj)}
              onDelete={(commentId) => handleDelete(commentId)}
              subComments={comments.filter((subitem) => subitem.parent == item.id)}
            />
          ))}
      </div>
      {user ? (
        <CommentInput article_id={articleId} needRefresh={() => fetchData(articleId)} parentComment={focusedComment} cancelSubCommentReply={() => setfocusedComment(null)} />
      ) : (
        <div className="mt-4" style={{ marginBottom: "24px", color: "GrayText", fontFamily: "GMarket-M", textAlign: "center" }}>
          로그인 후 댓글을 작성할 수 있습니다.
        </div>
      )}
    </div>
  );
}

export default CommentList;
