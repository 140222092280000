import { create } from "zustand";

export const useUserStore = create((set) => ({
  user: null,
  site: null,
  permission: null,
  availableSites: [],
  sitePaymentKeys: [],
  isWebPurchase: false,
  isWebPurchaseLodge: false,
  isWebPurchaseTicket: false,
  isWebShopping: false,
  isManaged: false,
  adminInquiry: false,
  adminTargetSubdomain: null,

  setUser: (user) => set({ user }),
  setSite: (site) => set({ site }),
  logout: () => set({ user: null }),
  setPermission: (permission) => set({ permission }),
  setAvailableSites: (availableSites) => set({ availableSites }),
  setSitePaymentKeys: (sitePaymentKeys) => set({ sitePaymentKeys }),
  setIsWebPurchase: (isWebPurchase) => set({ isWebPurchase }),
  setIsWebPurchaseLodge: (isWebPurchaseLodge) => set({ isWebPurchaseLodge }),
  setIsWebPurchaseTicket: (isWebPurchaseTicket) => set({ isWebPurchaseTicket }),
  setIsWebShopping: (isWebShopping) => set({ isWebShopping }),
  setIsManaged: (isManaged) => set({ isManaged }),
  setAdminInquiry: (adminInquiry) => set({ adminInquiry }),
  setAdminTargetSubdomain: (adminTargetSubdomain) => set({ adminTargetSubdomain }),
}));

// user: {
//     "id": ,
//     "phone": "",
//     "username": "",
//     "membership": "일반",
//     "thumbnail_img": "",
//     "profile_img": ",
//     "permission": "관리자"
// }

// permission: {
// permission: 2,
// subdomain: 'test'
// }
