import React from "react";

import Navbar from "../../../Components/Common/navbar";
import OrderProcess from "./OrderProcess";

const OrderPage = () => {
  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <OrderProcess />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderPage;
