import React from "react";

import Navbar from "../../Components/Common/navbar";
import NavBottomBar from "../../Components/Common/NavBottomBar";
import LodgingPackageCalendar from "./calendar";

const LodgingPackagePage = () => {

    return (
        <React.Fragment>
            <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa", minHeight: "100vh" }}>
                <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
                    <Navbar activeBar={6} />

                    <LodgingPackageCalendar />

                    <NavBottomBar activeBar={6} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default LodgingPackagePage;
