import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col, Button, Table } from "reactstrap";

import Navbar from "../../../Components/Common/navbar";

const OrderFail = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
                <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
                    <Navbar />
                    <h3 className="border-success text-center my-3 fw-bold mt-4" style={{ fontFamily: "Gmarket-M" }}>
                        주문실패
                    </h3>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <p className="text-center">주문이 실패하였습니다.</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div className="d-flex justify-content-center">
                        <Button onClick={() => navigate("/my-page")} color="success" style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", boxShadow: "none", border: "none" }}>
                            마이페이지 가기
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default OrderFail;
