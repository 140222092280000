import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, BACKEND_HOST } from "../../../api";
import { getAccessToken } from "../../../api/auth";
import MyEditor from "../../../Components/Common/MyEditor";
import ImagesUploader from "../Form/ImagesUploader";
import { useUserStore } from "../../../store/store";

function CommentInput({ parentComment, article_id, needRefresh, cancelSubCommentReply }) {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [groupKey, setGroupKey] = useState(Math.random().toString(16).slice(2));
  const [images, setImages] = useState([]);
  const [user, setUser, permission] = useUserStore((state) => [state.user, state.setUser, state.permission]);

  const checkData = () => {
    if (!content) {
      return null;
    }
    // const userId = getUserId()
    // if (userId === null) navigate(`/`);
    const data = {
      // "user": userId,
      article: article_id,
      content: content,
      images: images.map((_image) => _image.id),
    };
    if (parentComment) data["parent"] = parentComment.id;
    return data;
  };

  const userId = useMemo(() => {
    if (user) {
      return user.id;
    } else {
      return null;
    }
  }, [user]);

  const handlePost = useCallback(() => {
    const data = checkData();
    console.log("sendData", data);
    if (data === null) {
      // alert("내용을 입력해주세요.");
      return;
    } else {
      const accessToken = getAccessToken();
      if (accessToken) {
        API.post(`/inquiry/comment/`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.data)
          .then((data) => {
            console.log("POST inquiry/comments", data);
            alert("등록 완료");
            setContent("");
            // navigate(`/inquiry`)
            needRefresh();
          })
          .catch((e) => console.log(e));
      }
    }
  }, [parentComment, checkData]);

  return (
    <div className="mt-4  mb-4" style={parentComment ? { backgroundColor: "lightgoldenrodyellow", borderRadius: "4px", padding: "4px" } : null}>
      {parentComment ? (
        <div
          style={{ width: "100%", padding: "2px 8px" }}
          onClick={() => {
            cancelSubCommentReply();
          }}
        >
          to {parentComment.username}
          <span style={{ marginLeft: "18px", padding: "0 4px", background: "lightgray", borderRadius: "4px" }}>x</span>
        </div>
      ) : null}

      <ImagesUploader key={groupKey} groupKey={groupKey} userId={userId} onUploaded={setImages} />
      <div className="d-flex flex-row justify-content-between mt-3">
        <textarea
          style={{ width: `calc(100% - 80px)`, padding: "10px 15px", fontSize: "14px", lineHeight: "20px", resize: "none" }}
          className="form-control"
          placeholder="댓글을 작성해 주세요."
          rows="2"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        ></textarea>

        <button style={{ width: "74px", backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M" }} type="button" className="btn pull-right" onClick={() => handlePost()}>
          등록
        </button>
      </div>
    </div>
  );
}

export default CommentInput;
