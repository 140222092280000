import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionItem, Card, CardBody, Col, Collapse, Row, Button, Modal, ModalBody, ModalHeader, Container } from "reactstrap";

import _default from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/themes/material_orange.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { ColorRing, Oval } from "react-loader-spinner";

import { API } from "../../api";
import { useUserStore } from "../../store/store";
import { getAccessToken } from "../../api/auth";
import campIcon from "../../assets/images/camp_icon.png";
import { SUBDOMAIN } from "../../assets/meta/meta";
import moment from "moment";
import flatpickr from "flatpickr";
import PackageDetailSection from "./packageDetailSection";
import { getNextFriday, getNextSaturday } from "../../common/utils/dateUtil";
import { numberWithCommas } from "../../common/utils/format_util";

const LodgingPackageCalendar = ({
    membership_product_id = "",
    package_api = "/package/lodge-package/",
    skip_to_order = false,
    use_amazingpass = false
}) => {
    const navigate = useNavigate();

    // const [calendarOpen, setCalendarOpen] = useState(true);
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");

    const [packages, setPackages] = useState([]);
    const [toggleId, setToggleId] = useState(-1);
    const [selectedSpotId, setSelectedSpotId] = useState([]);


    const [user] = useUserStore((state) => [state.user]);

    const handleDate = (dates) => {
        if (dates.length < 2) return ["", ""];
        if (!(dates[0] instanceof Date) || !(dates[1] instanceof Date)) return ["", ""];

        const startDate = moment(dates[0]).format("YYYY-MM-DD");
        const endDate = moment(dates[1]).format("YYYY-MM-DD");
        console.log("startDate", startDate);
        console.log("endDate", endDate);
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);

        return [startDate, endDate];
    }

    const requestPackageProducts = async (dates) => {
        try {
            const [startDate, endDate] = handleDate(dates);
            console.log("startDate", startDate);
            console.log("endDate", endDate);

            if (startDate === "" || endDate === "") return;

            const response = await API.get(`${package_api}?start_date=${startDate}&end_date=${endDate}&subdomain=${SUBDOMAIN}${membership_product_id ? `&membership_product_id=${membership_product_id}` : ""}`);
            const data = response.data;

            setPackages(data);
            // setToggleId(-1);
            setSelectedSpotId([]);
        } catch (error) {
            console.error(error);
        }
    };


    // const isWaitState = () => {
    //     let selectedPackage = packages.find((x) => x.id === toggleId);
    //     if (toggleId !== -1 && selectedSpotId.length === 0 && selectedPackage.count <= selectedPackage.reserved) return true;
    //     else return false;
    // };

    useEffect(() => {
        // 컴포넌트가 처음 렌더링될 때 기본 날짜를 설정하고 API 요청을 보냅니다.
        requestPackageProducts([getNextFriday(), getNextSaturday()]);
    }, []);

    return (
        <Container className="justify-content-center" style={{ minHeight: "100vh" }}>
            <Row className="justify-content-center pb-5 pt-3">
                <Col lg={11} sm={10}>
                    {toggleId === -1 ? <div>
                        <Flatpickr
                            key="1"
                            onChange={requestPackageProducts}
                            className="form-control fs-17"
                            options={{
                                locale: _default.ko,
                                inline: true,
                                dateFormat: "Y-m-d",
                                minDate: "today",
                                defaultDate: [getNextFriday(), getNextSaturday()],
                                mode: "range",
                            }}
                        />
                    </div> : <div>
                        <Flatpickr
                            key="2"
                            onChange={requestPackageProducts}
                            className="form-control fs-17"
                            options={{
                                locale: _default.ko,
                                dateFormat: "Y-m-d",
                                minDate: "today",
                                defaultDate: [getNextFriday(), getNextSaturday()],
                                mode: "range",
                            }}
                        />
                    </div>}

                    {/* package product list */}
                    <div className="mt-2 bg-white rounded">
                        <div className="live-preview py-3 pb-5 d-flex flex-column gap-3" >
                            {packages.length !== 0 ? (
                                packages.map((item) => {
                                    return (
                                        <div key={item.id} style={{
                                        }}>
                                            {(toggleId === -1) ? <div className="" id={`headingOne${item.id}`} style={{ height: "90px" }}>
                                                <button
                                                    className={classnames("accordion-button", { collapsed: item.id === toggleId })}
                                                    type="button"
                                                    onClick={() => {
                                                        // setToggleId(item.id === toggleId ? -1 : item.id);
                                                        navigate(`/lodging-package/${item.id}?start-date=${selectedStartDate}&end-date=${selectedEndDate}`);
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: item.id === toggleId ? "bold" : "normal",
                                                        fontFamily: "Gmarket-M",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-start",

                                                        borderRadius: "10px",
                                                        border: "1px solid rgba(0,0,0,0.2)",
                                                        padding: "10px 12px",
                                                        // boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.2)",
                                                    }}
                                                // disabled={item.count <= item.reserved}
                                                >
                                                    <div>

                                                        {item.images.length !== 0 ? (
                                                            <img src={item.images[0]} style={{ width: "70px", height: "70px", objectFit: "cover", borderRadius: "10px", marginRight: "20px" }} alt="thumnail" />
                                                        ) : (
                                                            <img src={campIcon} style={{ width: "70px", height: "70px", objectFit: "contain", borderRadius: "10px", marginRight: "20px" }} alt="thumnail" />
                                                        )}
                                                    </div>

                                                    <div className="d-flex flex-column">
                                                        {item.proccessed.count <= item.proccessed.reserved || item.proccessed.price === 0 ? (
                                                            <div style={{ color: "#f7ccc3" }}>
                                                                {`${item.name}(${item.proccessed.reserved}/${item.proccessed.count})`} {item.proccessed.spots?.length && item.proccessed.spots[0].reason === "close" ? "예약불가" : "매진(대기가능)"}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <span style={{ lineHeight: "24px" }}>
                                                                    {item.name} ({item.proccessed.reserved}/{item.proccessed.count})
                                                                    <br />
                                                                    {numberWithCommas(item.proccessed.price)}원(1박) ~
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </button>
                                            </div> : <div className="mb-2">
                                                {(item.id === toggleId) ? <button
                                                    className={classnames("accordion-button", { collapsed: item.id === toggleId })}
                                                    type="button"
                                                    onClick={() => {
                                                        setToggleId(-1);
                                                        setSelectedSpotId([]);
                                                    }}
                                                    style={{
                                                        display: (item.id === toggleId) ? "block" : "none",
                                                        cursor: "pointer",
                                                        fontWeight: "bold",
                                                        fontFamily: "Gmarket-M",
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                        justifyContent: "flex-start",
                                                    }}
                                                // disabled={item.count <= item.reserved}
                                                >
                                                    목록보기
                                                </button> : null}
                                            </div>}


                                        </div>
                                    );
                                })
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Oval visible={true} height="50" width="50" color="#ff8a65" ariaLabel="oval-loading" secondaryColor="#fff" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ height: 100, borderWidth: 2 }}></div>
                </Col>
            </Row>

        </Container>
    );
}

export default LodgingPackageCalendar;