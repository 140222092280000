import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ListGroup, ListGroupItem, Badge, Button, Card, CardBody, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import Navbar from "../../Components/Common/navbar";
import SwiperCore, { Autoplay } from "swiper";
import styled from "styled-components";

import { useLocation } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { API, BACKEND_HOST } from "../../api";
import { SITE_ID } from "../../assets/meta/meta";
import { IoArrowBackOutline } from "react-icons/io5";
import { useUserStore } from "../../store/store";
import { getAccessToken, removeAccessToken } from "../../api/auth";
import { useQuery } from "react-query";

const ProfileContent = styled.div`
  background-color: yellowgreen;
  /* background-image: url('https://cdn.imweb.me/thumbnail/20220623/f7fd1b68c5e92.jpg');
    background-size:100% 100%; */
`;

const Line1 = styled.div`
  background-color: green;
`;

const Btn = styled.div`
  :hover {
    transform: scale(1.2);
    transition: 0.3s;
  }
`;

const BtnImg = styled.img``;
export default function MyPage({ tabparam = "index" }) {
  SwiperCore.use([Autoplay]);
  const accessToken = getAccessToken();

  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);

  // const [displayCategory, setCategory] = useState("All");
  const [voucherCount, setVoucherCount] = useState(0);

  const [reservGiftCount, setReservGiftCount] = useState(0);
  const [ticketGiftCount, setTicketGiftCount] = useState(0);
  const [CouponGiftCount, setCouponGiftCount] = useState(0);
  const [userVoucherGiftCount, setUserVoucherGiftCount] = useState(0);

  /* 임시 이미지 */
  const [thumbnailImg, setThumbnailImg] = useState(`${BACKEND_HOST}/media/ProfileBlank.png`);
  const [activeTab, setActiveTab] = useState("0");
  const [myQRcode, setMyQRcode] = useState("");

  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    // console.log("location", location)
    const tab = location.state?.tab;
    if (tab) {
      // console.log("location", tab)
      setActiveTab(tab);
    }
  }, [location]);

  // React.useEffect(() => {
  //     if (tabparam === "gift-box") {
  //         setActiveTab('5');
  //     }
  // }, [tabparam])

  const [kakaoChannelsId, setkakaoChannelsId] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(`${BACKEND_HOST}/sites/kakao-channels/${SITE_ID}`);
        const data = response.data;

        setkakaoChannelsId(data.kakaoChannelsId);
      } catch (error) {
        console.error("Fetching Kakao Channels failed", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // get code from url parameters
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const message = urlParams.get("message");
    if (code) {
      // console.log("code", code);
      // console.log("message", message);
      alert(`결제가 실패하였습니다. ${code}`, message);

      // remove code from url
      window.history.replaceState({}, document.title, "/my-page");
    }
  }, []);

  React.useEffect(() => {

    const fetchData = async () => {
      if (accessToken && user) {
        const phoneNum = user.phone;

        try {
          // Voucher Count (unchecked)
          const voucherResponse = await API.get(`/voucher/unchecked-count/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const voucherData = voucherResponse.data;
          setVoucherCount(voucherData.unchecked_count);

          // UserVoucherGift Count
          const voucherGiftResponse = await API.get(`/voucher/user-voucher-gift/?recipient=${phoneNum}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const voucherGiftData = voucherGiftResponse.data;
          const giftCount = voucherGiftData.results.filter((gift) => gift.voucher_state === "사용안함").length;
          setUserVoucherGiftCount(giftCount);

          // Reservation Gift Count
          const reservationGiftResponse = await API.get(`/package/reservation-gift/?recipient=${phoneNum}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const reservationGiftData = reservationGiftResponse.data;
          const reservGiftCount = reservationGiftData.results.filter((gift) => gift.reserv_state === "예약").length;
          setReservGiftCount(reservGiftCount);

          // Coupon Gift Count
          const couponGiftResponse = await API.get(`/coupon/gift/count/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const couponGiftData = couponGiftResponse.data;
          setCouponGiftCount(couponGiftData.count);

          // Ticket Gift Count
          const ticketGiftResponse = await API.get(`/ticket/ticket-gift/?recipient=${phoneNum}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const ticketGiftData = ticketGiftResponse.data;
          const ticketGiftCount = ticketGiftData.results.filter((gift) => gift.ticket_state === "사용안함").length;
          setTicketGiftCount(ticketGiftCount);

          // User QR Code
          const userQRResponse = await API.get(`/accounts/user-qr/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const userQRData = userQRResponse.data;
          const qrcode = userQRData["qrcode"];
          setMyQRcode(qrcode);

          // Profile Image
          const profileImgResponse = await API.get(`/accounts/profile-img/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const profileImgData = profileImgResponse.data;
          const thumbnailUrl = profileImgData["profile_img"];
          if (thumbnailUrl) {
            setThumbnailImg(thumbnailUrl);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [user]);


  const {
    data: membershipStatus,
    isLoading: isMembershipLoading,
    error: membershipError,
    refetch: getMembershipStatus,
  } = useQuery("membership-status", () =>
    API.get(`/membership/memberships/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );


  function routeToMypage(route) {
    return navigate(`/my-page/${route}`);
  }

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
            {/* <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg"></div>
                    </div> */}
            {activeTab === "0" ? (
              <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 mt-3">
                <div className=" d-flex flex-column align-items-center justify-content-between flex-row">
                  <div className="col-auto">
                    <div className="d-flex justify-content-center" style={{ flexDirection: "column", alignItems: "center" }}>
                      {/* <img className="fluid" src={thumbnailImg} style={{ objectFit: "cover", width: "92px", height: "92px", borderRadius: "42%" }} alt="profile image" /> */}
                      <QRCodeSVG value={myQRcode} size="86px" />
                      <h3 className="text-dark mt-3 mb-1" style={{ textAlign: "center", fontFamily: "Gmarket-M", fontSize: "18px" }}>
                        {user?.username}님 안녕하세요!
                      </h3>
                    </div>
                  </div>
                  <div style={{ marginBottom: "12px" }}></div>
                </div>
              </div>
            ) : null}

            <div style={{ marginBottom: "18px" }}></div>

            {/* {activeTab === "0" ? <ReservationList navigate={navigate} todayOnly /> : null} */}

            <Row>
              <Col lg={12}>
                <div>
                  {activeTab === "0" ? (
                    <div style={{ padding: "0 10px" }}>
                      <ListGroup flush>
                        <div className="px-1">
                          <h5 style={{ fontFamily: "Gmarket-M", color: "#9e9e9e" }}>예약 / 주문</h5>
                        </div>
                        
                        {membershipStatus?.data.length ? <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("membership");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>멤버십 전용 구매</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem> : null}

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("shopping-purchase");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>쇼핑 주문내역</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("reservation");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>예약내역</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("ticket");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>티켓내역</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("voucher");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>
                            교환권
                            {voucherCount ? (
                              <Badge color="danger" style={{ marginLeft: "5px" }}>
                                {voucherCount}
                              </Badge>
                            ) : null}
                          </span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("amazingpass");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>어메이징패스(예약권)</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("coupon");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>쿠폰함</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <div style={{ width: "100%", height: "1px", backgroundColor: "#e9ebec", margin: "15px 0" }}></div>

                        <div className="mt-3 px-1">
                          <h5 style={{ fontFamily: "Gmarket-M", color: "#9e9e9e" }}>선물</h5>
                        </div>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("gift-send");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>선물하기</span>

                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("gift-box");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>
                            선물함
                            {userVoucherGiftCount || reservGiftCount || ticketGiftCount || CouponGiftCount ? (
                              <Badge color="danger" style={{ marginLeft: "5px" }}>
                                {userVoucherGiftCount + reservGiftCount + ticketGiftCount + CouponGiftCount}
                              </Badge>
                            ) : null}
                          </span>

                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        {user?.permission === "관리자" || (user?.site_permission?.site === SITE_ID && [1, 2, 5, 6].includes(user?.site_permission?.permission)) ? (
                          <>
                            <ListGroupItem
                              tag="a"
                              style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                              onClick={() => {
                                routeToMypage("b2b/coupon");
                              }}
                              className="d-flex justify-content-between"
                            >
                              <span style={{ color: "#ff5a5f", fontWeight: 600 }}>B2B 쿠폰 선물</span>

                              <i className="ri-arrow-right-s-line"></i>
                            </ListGroupItem>
                            <ListGroupItem
                              tag="a"
                              style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                              onClick={() => {
                                routeToMypage("b2b/precoupon");
                              }}
                              className="d-flex justify-content-between"
                            >
                              <span style={{ color: "#ff5a5f", fontWeight: 600 }}>B2B 예약권 선물</span>

                              <i className="ri-arrow-right-s-line"></i>
                            </ListGroupItem>
                            <ListGroupItem
                              tag="a"
                              style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                              onClick={() => {
                                routeToMypage("b2b/voucher");
                              }}
                              className="d-flex justify-content-between"
                            >
                              <span style={{ color: "#ff5a5f", fontWeight: 600 }}>B2B 교환권 선물</span>

                              <i className="ri-arrow-right-s-line"></i>
                            </ListGroupItem>
                          </>
                        ) : null}

                        <div style={{ width: "100%", height: "1px", backgroundColor: "#e9ebec", margin: "15px 0" }}></div>

                        <div className="mt-3 px-1">
                          <h5 style={{ fontFamily: "Gmarket-M", color: "#9e9e9e" }}>문의</h5>
                        </div>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            navigate("/inquiry/form/0");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>문의하기</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <div style={{ width: "100%", height: "1px", backgroundColor: "#e9ebec", margin: "15px 0" }}></div>

                        <div className="mt-3 px-1">
                          <h5 style={{ fontFamily: "Gmarket-M", color: "#9e9e9e" }}>결제</h5>
                        </div>

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            routeToMypage("cancel");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>취소내역</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <div style={{ width: "100%", height: "1px", backgroundColor: "#e9ebec", margin: "15px 0" }}></div>

                        {/* <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            toggleTab("4");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>정보수정</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem> */}
                        {/* <ListGroupItem tag="a" href="#" style={{ fontSize: "16px" }} onClick={() => { toggleTab('6'); }} className="d-flex justify-content-between"><span>Privacy & Security</span><i className="ri-arrow-right-s-line"></i></ListGroupItem> */}
                        {/* <ListGroupItem tag="a" href="#" style={{ fontSize: "16px" }} onClick={() => {
                                                window.open(`https://pf.kakao.com/${kakaoChannelsId}`, "_blank")
                                            }} className="d-flex justify-content-between"><span>1:1문의</span><i className="ri-arrow-right-s-line"></i></ListGroupItem> */}

                        <ListGroupItem
                          tag="a"
                          style={{ fontSize: "16px", border: "none", padding: "15px 15px", cursor: "pointer" }}
                          onClick={() => {
                            window.localStorage.removeItem("refresh_token");
                            removeAccessToken();
                            setUser(null);

                            if (location.pathname === "/") navigate(0);
                            else navigate("/");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>로그아웃</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>
                      </ListGroup>
                    </div>
                  ) : (
                    <div style={{ backgroundColor: "#fff", padding: "10px 0" }} className="sticky-top">
                      <Button
                        color="white"
                        className="add-btn"
                        style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}
                        onClick={() => {
                          toggleTab("0");
                        }}
                        id="edit-btn"
                      >
                        <IoArrowBackOutline size={26} />
                      </Button>
                    </div>
                  )}

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
