import moment from "moment";
import React from "react";
import { Button } from "reactstrap";
import { BACKEND_HOST } from "../../../api";
import SubCommentBox from "./SubCommentBox";
import { getUserId } from "../../../api/auth";

function CommentBox({ id, user_id, username, content, profile_img, images, written_date, onReply, subComments, onDelete }) {
  const self_user_id = getUserId();

  function hyperlinkUrls(text) {
    const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|www\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*\.[a-z]{2,})/gi;
    const imageTagRegex = /<img\b(?!.*\bstyle\b)([^>]*)(?<!\/)>/gi;

    // Replace URLs with hyperlinks
    let updatedText = text.replace(urlRegex, function (url) {
      let prefixedUrl = url.startsWith("www.") ? `http://${url}` : url;
      return `<a href="${prefixedUrl}" style="color:#f6475f !important" target="_blank">${url}</a>`;
    });

    // Add style to image tags
    updatedText = updatedText.replace(imageTagRegex, function (imgTag) {
      return imgTag.replace(/<img\b/, '<img style="max-width: 450px;width:100%;height:auto;"');
    });

    return updatedText;
  }

  return (
    <div key={id}>
      <div className="d-flex flex-row justify-content-start align-content-start gap-3 m-2 mb-4">
        {/* <a href="#" className="pull-left"> */}
        <div>
          <img src={profile_img || `${BACKEND_HOST}/media/ProfileBlank.png`} alt="" className="rounded-circle" style={{ width: "35px", height: "35px" }} />
        </div>
        <div className="media-body" style={{ width: "100%" }}>
          <span className="">
            <strong className="text-primary">{username}</strong>
          </span>
          <span className="text-muted mx-2">
            <small className="text-muted" style={{ fontSize: "11px" }}>
              {moment(written_date).format("YYYY-MM-DD HH:mm")}
            </small>
          </span>
          <span>
            <span
              className="btn btn-link"
              style={{ padding: "2px", boxShadow: "none" }}
              onClick={() =>
                onReply({
                  id,
                  username,
                  content,
                })
              }
            >
              댓글
            </span>
          </span>
          {String(self_user_id) === String(user_id) ? (
            <span>
              <span
                className="btn btn-link"
                style={{ padding: "2px", boxShadow: "none" }}
                onClick={() => {
                  if (window.confirm("정말로 댓글을 삭제하시겠습니까?")) onDelete(id);
                }}
              >
                삭제
              </span>
            </span>
          ) : null}

          <div dangerouslySetInnerHTML={{ __html: hyperlinkUrls(content) }} style={{ maxWidth: "600px", marginTop: "5px", lineHeight: "24px", marginBottom: "10px", width: "90%" }} />

          {images
            ? images.map((image) => (
                <div key={image.id}>
                  <img src={`${image.image}`} style={{ maxWidth: "200px", width: "100%", cursor: "pointer" }} alt="commentimg" onClick={() => window.open(image.image, "_blank")} />
                </div>
              ))
            : null}
        </div>
      </div>
      <div>{subComments ? subComments.map((item) => <SubCommentBox key={item.id} {...item} onDelete={(_id) => onDelete(_id)} />) : null}</div>
    </div>
  );
}

export default CommentBox;
